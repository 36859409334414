import React, { useState, useEffect } from 'react';
import NoteTaking from './NoteTaking';
import ZoomMeeting from './ZoomMeeting';

const SessionContainer = ({ sessionData, isCurrentSession, onEndSession, onDeleteSession, currentUser, patients }) => {
  const [currentSessionData, setCurrentSessionData] = useState(sessionData);
  const [showZoomMeeting, setShowZoomMeeting] = useState(false);

  useEffect(() => {
    setCurrentSessionData(sessionData);
  }, [sessionData]);

  const handleSubmit = async (updatedSessionData) => {
    try {
      await onEndSession(updatedSessionData);
    } catch (error) {
      console.error('Error ending session:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await onDeleteSession();
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const startZoomMeeting = () => {
    setShowZoomMeeting(true);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold">
        {isCurrentSession ? 'Current Session' : 'Previous Session'}
      </h3>
      {showZoomMeeting ? (
        <div className="bg-white p-4 rounded-md shadow-md">
          <ZoomMeeting
            meetingNumber={currentSessionData.zoom_meeting_id}
            userName={currentUser.full_name}
          />
        </div>
      ) : (
        <div className="bg-gray-100 p-4 rounded-md">
          <button
            onClick={startZoomMeeting}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            Start Zoom Meeting
          </button>
        </div>
      )}
      {!showZoomMeeting && (
        <NoteTaking
          initialData={currentSessionData}
          onSubmit={handleSubmit}
          onCancel={handleDelete}
          currentUser={currentUser}
          patients={patients}
          isPreviousSession={!isCurrentSession}
        />
      )}
    </div>
  );
};

export default SessionContainer;