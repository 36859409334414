import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, TimeScale);

const WellbeingScoreGraph = ({ sessions }) => {
  const sortedSessions = sessions.sort((a, b) => new Date(a.date) - new Date(b.date));

  const data = {
    labels: sortedSessions.map(session => new Date(`${session.date}T${session.time}`)),
    datasets: [
      {
        label: 'Wellbeing Score',
        data: sortedSessions.map(session => ({
          x: new Date(`${session.date}T${session.time}`),
          y: session.wellbeing_score
        })),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Wellbeing Score Over Time',
        font: {
          size: 16,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          weight: 'normal',
        },
        padding: {
          top: 10,
          bottom: 30
        },
        color: '#333',
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#333',
        bodyColor: '#666',
        borderColor: '#ccc',
        borderWidth: 1,
        cornerRadius: 4,
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            return new Date(tooltipItems[0].parsed.x).toLocaleDateString();
          },
          label: (context) => {
            return `Score: ${context.parsed.y}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
          font: {
            size: 10,
          },
          color: '#666',
        },
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: '#e0e0e0',
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: '#e0e0e0',
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 5,
          font: {
            size: 10,
          },
          color: '#666',
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 10
      },
    },
  };

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default WellbeingScoreGraph;