import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';
import { api } from '../../../services/api';
import WellbeingScore from '../WellbeingScore/WellbeingScore';

const NoteTaking = ({ initialData, onSubmit, onCancel, currentUser, patients, isPreviousSession = false }) => {
  const [noteData, setNoteData] = useState({
    patient_id: '',
    session_number: '',
    date: new Date().toISOString().split('T')[0],
    time: new Date().toTimeString().split(' ')[0],
    content: '',  // Initialize with an empty string instead of null
    format: 'SOAP',
    summary: '',
    ...initialData
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);

  useEffect(() => {
    if (initialData) {
      setNoteData(prevData => ({
        ...prevData,
        ...initialData,
        content: initialData.content || ''  // Ensure content is never null
      }));
    }
  }, [initialData]);

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf']
    },
    maxFiles: 1
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoteData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSaving(true);

    try {
      let fileUrl = '';
      if (uploadedFile) {
        fileUrl = await api.files.uploadFile(currentUser.id, uploadedFile);
      }

      const sessionPayload = {
        ...noteData,
        file_url: fileUrl
      };

      await onSubmit(sessionPayload);
      setIsSaving(false);
    } catch (error) {
      console.error('Error saving session:', error);
      setError(`Error saving session: ${error.message || 'Unknown error occurred'}`);
      setIsSaving(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (!noteData.content && !uploadedFile) {
      setError('Please enter note content or upload a file before enhancing');
      return;
    }

    setError('');
    setIsSummarizing(true);
    try {
      let content = noteData.content;
      if (uploadedFile) {
        const fileUrl = await api.files.uploadFile(currentUser.id, uploadedFile);
        content = fileUrl;
      }

      const response = await api.sessions.summarize(content, noteData.format);

      if (response && response.summary) {
        setNoteData(prevData => ({
          ...prevData,
          summary: response.summary
        }));
      } else {
        throw new Error('Failed to generate summary');
      }
    } catch (error) {
      console.error('Error generating summary:', error);
      setError(`Error enhancing notes: ${error.message || 'Unknown error occurred'}`);
    } finally {
      setIsSummarizing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="patient_id" className="block text-sm font-medium text-gray-700">Patient</label>
        <select
          id="patient_id"
          name="patient_id"
          value={noteData.patient_id}
          onChange={handleInputChange}
          disabled={true}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        >
          <option value="">Select a patient</option>
          {patients.map((patient) => (
            <option key={patient.id} value={patient.id}>{patient.full_name}</option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="session_number" className="block text-sm font-medium text-gray-700">Session Number</label>
        <input
          type="number"
          id="session_number"
          name="session_number"
          value={noteData.session_number}
          onChange={handleInputChange}
          disabled={true}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={noteData.date}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
          <input
            type="time"
            id="time"
            name="time"
            value={noteData.time}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="content" className="block text-sm font-medium text-gray-700">Note Content (Optional)</label>
        <textarea
          id="content"
          name="content"
          rows="4"
          value={noteData.content || ''}  // Ensure value is never null
          onChange={handleInputChange}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        ></textarea>
      </div>

      <div className="my-4 text-center">
        <span className="text-gray-500">or</span>
      </div>

      <div>
        <label htmlFor="file" className="block text-sm font-medium text-gray-700 mb-2">Upload Handwritten or Typed Notes (Optional)</label>
        <div {...getRootProps()} className={`border-2 border-dashed border-gray-300 rounded-md p-4 text-center cursor-pointer ${isDragActive ? 'border-primary' : ''}`}>
          <input {...getInputProps()} />
          <FaUpload className="mx-auto text-gray-400 mb-2" />
          <p className="text-gray-500">Drag & drop a file here, or click to select a file</p>
        </div>
        {uploadedFile && (
          <div className="mt-2 bg-gray-100 p-2 rounded-md">
            <p className="text-sm text-gray-600">Selected file: {uploadedFile.name}</p>
          </div>
        )}
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">Format</label>
        <div className="flex items-center space-x-2">
          <select
            name="format"
            value={noteData.format}
            onChange={handleInputChange}
            className="block w-40 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          >
            <option value="SOAP">SOAP</option>
            <option value="DAP">DAP</option>
            <option value="BIRP">BIRP</option>
            {/* Add other format options as needed */}
          </select>
          <button
            type="button"
            onClick={handleGenerateSummary}
            disabled={isSummarizing || (!noteData.content && !uploadedFile)}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            {isSummarizing ? 'Enhancing...' : 'Enhance Notes'}
          </button>
        </div>
      </div>

      {noteData.summary && (
        <div className="mt-6 bg-white shadow-md rounded-lg p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Enhanced Notes Summary</h3>
          <div className="prose max-w-none" dangerouslySetInnerHTML={{
            __html: noteData.summary.replace(
              /<h3 class="(.*?)">(.*?)<\/h3>/g,
              '<h4 class="font-semibold text-gray-800 mb-2">$2</h4>'
            ).replace(
              /<p class="(.*?)">(.*?)<\/p>/g,
              '<p class="text-gray-600 mb-4">$2</p>'
            ).replace(
              /<ul class="(.*?)">(.*?)<\/ul>/gs,
              '<ul class="list-disc list-inside text-gray-600 mb-4">$2</ul>'
            )
          }} />
          <WellbeingScore score={noteData.wellbeing_score} />
        </div>
      )}

      {error && <p className="text-red-500">{error}</p>}

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {isPreviousSession ? 'Cancel' : 'Delete Session'}
        </button>
        <button
          type="submit"
          disabled={isSaving}
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
        >
          {isSaving ? 'Saving...' : (isPreviousSession ? 'Save Session' : 'End Session')}
        </button>
      </div>
    </form>
  );
};

export default NoteTaking;