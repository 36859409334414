import React, { useState } from 'react';
import NoteTaking from './NoteTaking';

const PreviousSessionForm = ({ patients, onSubmit, onCancel, currentUser }) => {
  const [sessionData, setSessionData] = useState({
    patient_id: '',
    session_number: '',
    date: '',
    time: '',
  });
  const [showNoteTaking, setShowNoteTaking] = useState(false);

  const handleInputChange = (e) => {
    setSessionData({ ...sessionData, [e.target.name]: e.target.value });
  };

  const handleSessionSubmit = (e) => {
    e.preventDefault();
    setShowNoteTaking(true);
  };

  const handleNoteSubmit = (noteData) => {
    onSubmit({ ...sessionData, ...noteData });
  };

  if (showNoteTaking) {
    return (
      <NoteTaking
        initialData={sessionData}
        onSubmit={handleNoteSubmit}
        onCancel={() => setShowNoteTaking(false)}
        currentUser={currentUser}
        patients={patients}
        isPreviousSession={true}
      />
    );
  }

  return (
    <form onSubmit={handleSessionSubmit} className="space-y-4">
      <div>
        <label htmlFor="patient_id" className="block text-sm font-medium text-gray-700">Patient</label>
        <select
          id="patient_id"
          name="patient_id"
          value={sessionData.patient_id}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        >
          <option value="">Select a patient</option>
          {patients.map((patient) => (
            <option key={patient.id} value={patient.id}>{patient.full_name}</option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="session_number" className="block text-sm font-medium text-gray-700">Session Number</label>
        <input
          type="number"
          id="session_number"
          name="session_number"
          value={sessionData.session_number}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
        <input
          type="date"
          id="date"
          name="date"
          value={sessionData.date}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
        <input
          type="time"
          id="time"
          name="time"
          value={sessionData.time}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button type="button" onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark">
          Next
        </button>
      </div>
    </form>
  );
};

export default PreviousSessionForm;