import React from 'react';
import WellbeingScoreGraph from './WellbeingScore/WellbeingScoreGraph';

const PatientDetails = ({ patient, showWellbeingGraph, setShowWellbeingGraph, sessionsWithWellbeingScore }) => {
  return (
    <div>
      <h3 className="text-2xl font-semibold text-gray-700 mb-4">{patient.full_name}</h3>
      {patient.status === 'pending' && (
        <div className="mb-4 p-2 bg-yellow-100 text-yellow-800 rounded">Pending Approval</div>
      )}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h4 className="text-lg font-medium text-gray-700 mb-2">Personal Information</h4>
          <p><strong>Email:</strong> {patient.email || 'N/A'}</p>
          <p><strong>Date of Birth:</strong> {patient.dob || 'N/A'}</p>
          <p><strong>Phone:</strong> {patient.phone || 'N/A'}</p>
          <p><strong>Address:</strong> {patient.address || 'N/A'}</p>
        </div>
        <div>
          <h4 className="text-lg font-medium text-gray-700 mb-2">Medical Information</h4>
          <p><strong>Allergies:</strong> {patient.allergies || 'None reported'}</p>
          <p><strong>Current Medications:</strong> {patient.current_medications || 'None reported'}</p>
          <p><strong>Past Diagnoses:</strong> {patient.past_diagnoses || 'None reported'}</p>
        </div>
      </div>
      <div className="flex space-x-2 mb-4">
        {sessionsWithWellbeingScore.length > 2 && (
          <button
            onClick={() => setShowWellbeingGraph(!showWellbeingGraph)}
            className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition-colors duration-200"
          >
            {showWellbeingGraph ? 'Hide Wellbeing Graph' : 'Show Wellbeing Graph'}
          </button>
        )}
      </div>
      {showWellbeingGraph && sessionsWithWellbeingScore.length > 2 && (
        <div className="mb-6 bg-white p-4 rounded-lg shadow">
          <WellbeingScoreGraph sessions={sessionsWithWellbeingScore} />
        </div>
      )}
    </div>
  );
};

export default PatientDetails;