import React from 'react';

const WellbeingScoreBar = ({ score }) => {
  if (score === null || score === undefined) {
    return null;
  }

  const getColor = (score) => {
    if (score >= 80) return '#10B981'; // green-500
    if (score >= 60) return '#FBBF24'; // yellow-400
    if (score >= 40) return '#F97316'; // orange-500
    return '#EF4444'; // red-500
  };

  return (
    <div className="flex flex-col items-end">
      <span className="text-xs font-medium text-gray-500 mb-1">Wellbeing Score</span>
      <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
        <div 
          className="h-full rounded-full transition-all duration-500 ease-out"
          style={{ 
            width: `${score}%`, 
            backgroundColor: getColor(score) 
          }}
        />
      </div>
    </div>
  );
};

export default WellbeingScoreBar;