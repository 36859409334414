import React from 'react';
import { format, parseISO } from 'date-fns';
import { Moon, Heart } from 'lucide-react';
import { DashboardCard, getStatusColor, round } from '../HealthDashboardUtils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

const SleepTab = ({ sleepData }) => {
    // Add null check for sleepData
    if (!sleepData) {
        return <p>No sleep data available.</p>;
    }

    const sleepSummaryData = sleepData.map((entry) => ({
        date: format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd'),
        totalSleepHours: (entry.sleep_durations_data?.asleep?.duration_asleep_state_seconds || 0) / 3600,
        deepSleepHours: (entry.sleep_durations_data?.asleep?.duration_deep_sleep_state_seconds || 0) / 3600,
        efficiency: entry.sleep_durations_data?.sleep_efficiency || 0,
    }));

    return (
        <>
            <DashboardCard title="Sleep Over Time" icon={<Moon className="text-indigo-500" />}>
                {sleepSummaryData.length > 0 ? (
                    <>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={sleepSummaryData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <Bar dataKey="totalSleepHours" name="Total Sleep (hrs)" fill="#8884d8" />
                                    <Bar dataKey="deepSleepHours" name="Deep Sleep (hrs)" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="mt-4">
                            <h4 className="text-lg font-medium mb-2">Sleep Efficiency Over Time</h4>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={sleepSummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
                                        <Tooltip formatter={(value) => `${value}%`} />
                                        <Line type="monotone" dataKey="efficiency" name="Efficiency (%)" stroke="#f6ad55" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </>
                ) : (
                    <p>No sleep data available.</p>
                )}
            </DashboardCard>
            {sleepSummaryData.length > 0 && sleepSummaryData.map((entry) => (
                <DashboardCard key={entry.date} title={`Sleep Summary for ${entry.date}`} icon={<Moon className="text-indigo-500" />}>
                    <ul>
                        <li>Total Sleep: {round(entry.totalSleepHours)} hours</li>
                        <li>Deep Sleep: {round(entry.deepSleepHours)} hours</li>
                        <li className={getStatusColor(entry.efficiency, { good: 90, average: 80 })}>
                            Efficiency: {round(entry.efficiency)}%
                        </li>
                    </ul>
                </DashboardCard>
            ))}
            {sleepData[0]?.heart_rate_data && (
                <DashboardCard title="Heart Rate During Sleep" icon={<Heart className="text-red-500" />}>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={sleepData[0].heart_rate_data.detailed.hr_samples}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="timestamp"
                                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                                />
                                <YAxis />
                                <Tooltip
                                    labelFormatter={(timestamp) => new Date(timestamp).toLocaleString()}
                                    formatter={(value) => [`${value} bpm`, 'Heart Rate']}
                                />
                                <Line type="monotone" dataKey="bpm" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </DashboardCard>
            )}
        </>
    );
};

export default SleepTab;
