import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const GetStarted = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    fullName: '',
    gender: '',
    dob: '',
    email: '',
    phone: '',
    username: '',
    password: '',
    mentalHealthHistory: '',
    currentMedications: '',
    sleepPattern: '',
    exerciseFrequency: '',
    alcoholConsumption: '',
    caffeineConsumption: '',
    stressTriggers: '',
    relaxationHabits: '',
    role: 'user',
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signUp({
        email: userData.email,
        password: userData.password,
        options: {
          data: {
            full_name: userData.fullName,
            dob: userData.dob ? userData.dob.toISOString().split('T')[0] : null,
            gender: userData.gender,
            phone: userData.phone,
            role: 'user'
          }
        }
      });

      if (error) {
        if (error.message.includes("cannot be used as it is not authorized")) {
          alert("This email address is not authorized for sign-up. Please contact support or use a different email.");
        } else {
          alert(`Error during sign-up: ${error.message}`);
        }
        return;
      }

      // Insert additional user data into the 'users' table
      const { error: insertError } = await supabase
        .from('users')
        .insert({
          id: data.user.id,
          email: userData.email,
          username: userData.username,
          full_name: userData.fullName,
          dob: userData.dob ? userData.dob.toISOString().split('T')[0] : null,
          gender: userData.gender,
          phone: userData.phone,
          mental_health_history: userData.mentalHealthHistory,
          current_medications: userData.currentMedications,
          sleep_pattern: userData.sleepPattern,
          exercise_frequency: userData.exerciseFrequency,
          alcohol_consumption: userData.alcoholConsumption,
          caffeine_consumption: userData.caffeineConsumption,
          stress_triggers: userData.stressTriggers,
          relaxation_habits: userData.relaxationHabits
        });

      if (insertError) throw insertError;

      alert('Sign up successful!');
      navigate('/login');
    } catch (error) {
      console.error('Error during sign-up:', error);
      alert('An unexpected error occurred during sign-up. Please try again later.');
    }
  };

  const validateForm = () => {
    if (!userData.fullName || !userData.email || !userData.username || !userData.password) {
      alert("Please fill out all required fields.");
      return false;
    }
    return true;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
        {step === 1 && (
          <div className="basic-info-step">
            <h2 className="text-3xl font-bold text-primary mb-6 text-center">Create Your Account</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              if (validateForm()) {
                setStep(3);
              }
            }} className="space-y-4">
              <input type="text" name="fullName" placeholder="Full Name *" value={userData.fullName} onChange={handleInputChange} required className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              <input type="email" name="email" placeholder="Email *" value={userData.email} onChange={handleInputChange} required className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              <input type="text" name="username" placeholder="Username *" value={userData.username} onChange={handleInputChange} required className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              <input type="password" name="password" placeholder="Password *" value={userData.password} onChange={handleInputChange} required className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              <select name="gender" value={userData.gender} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                <option value="">Select Gender (Optional)</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <input type="tel" name="phone" placeholder="Phone Number (Optional)" value={userData.phone} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              <DatePicker
                selected={userData.dob}
                onChange={(date) => setUserData({ ...userData, dob: date })}
                dateFormat="yyyy-MM-dd"
                placeholderText="Date of Birth (Optional)"
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              />
              <button type="submit" className="w-full btn-primary">Next</button>
              <p className="text-center text-bodyText">
                Already have an account?{' '}
                <a href="/login" className="text-primary hover:underline">Log In</a>
              </p>
            </form>
          </div>
        )}
        {step === 3 && (
          <div className="health-survey-step">
            <p className="text-primary hover:underline cursor-pointer mb-4" onClick={handleBack}>
              Back
            </p>
            <h2 className="text-3xl font-bold text-primary mb-6 text-center">Health and Lifestyle Survey</h2>
            <form onSubmit={handleSubmit} onKeyPress={handleKeyPress} className="space-y-4">
              <div className="form-group">
                <label htmlFor="mentalHealthHistory" className="block text-sm font-medium text-bodyText mb-1">Mental Health History <span className="text-gray-500">(optional)</span></label>
                <textarea id="mentalHealthHistory" name="mentalHealthHistory" placeholder="Any existing mental health diagnoses" value={userData.mentalHealthHistory} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              </div>
              <div className="form-group">
                <label htmlFor="currentMedications" className="block text-sm font-medium text-bodyText mb-1">Current Medications <span className="text-gray-500">(optional)</span></label>
                <textarea id="currentMedications" name="currentMedications" placeholder="List any medications related to mental health" value={userData.currentMedications} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              </div>
              <div className="form-group">
                <label htmlFor="sleepPattern" className="block text-sm font-medium text-bodyText mb-1">Sleep Pattern</label>
                <select id="sleepPattern" name="sleepPattern" value={userData.sleepPattern} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                  <option value="">Select your typical sleep pattern</option>
                  <option value="less-than-6">Less than 6 hours</option>
                  <option value="6-to-8">6 to 8 hours</option>
                  <option value="more-than-8">More than 8 hours</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exerciseFrequency" className="block text-sm font-medium text-bodyText mb-1">Exercise Frequency</label>
                <select id="exerciseFrequency" name="exerciseFrequency" value={userData.exerciseFrequency} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                  <option value="">Select your exercise frequency</option>
                  <option value="rarely">Rarely</option>
                  <option value="1-2-times">1-2 times a week</option>
                  <option value="3-5-times">3-5 times a week</option>
                  <option value="daily">Daily</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="alcoholConsumption" className="block text-sm font-medium text-bodyText mb-1">Alcohol Consumption</label>
                <select id="alcoholConsumption" name="alcoholConsumption" value={userData.alcoholConsumption} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                  <option value="">Select your alcohol consumption</option>
                  <option value="none">None</option>
                  <option value="occasional">Occasional</option>
                  <option value="moderate">Moderate</option>
                  <option value="heavy">Heavy</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="caffeineConsumption" className="block text-sm font-medium text-bodyText mb-1">Caffeine Consumption</label>
                <select id="caffeineConsumption" name="caffeineConsumption" value={userData.caffeineConsumption} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                  <option value="">Select your caffeine consumption</option>
                  <option value="none">None</option>
                  <option value="1-2-cups">1-2 cups a day</option>
                  <option value="3-4-cups">3-4 cups a day</option>
                  <option value="more-than-4">More than 4 cups a day</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="stressTriggers" className="block text-sm font-medium text-bodyText mb-1">Stress Triggers <span className="text-gray-500">(optional)</span></label>
                <textarea id="stressTriggers" name="stressTriggers" placeholder="What typically triggers stress for you?" value={userData.stressTriggers} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              </div>
              <div className="form-group">
                <label htmlFor="relaxationHabits" className="block text-sm font-medium text-bodyText mb-1">Relaxation Habits <span className="text-gray-500">(optional)</span></label>
                <textarea id="relaxationHabits" name="relaxationHabits" placeholder="What do you do to relax?" value={userData.relaxationHabits} onChange={handleInputChange} className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" />
              </div>
              <button type="submit" className="w-full btn-primary">Create Account</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetStarted;