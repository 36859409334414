import React from 'react';
import { Dumbbell, HeartPulse, Speedometer } from 'lucide-react';
import { DashboardCard } from '../HealthDashboardUtils';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar,
} from 'recharts';
import { format, parseISO } from 'date-fns';

const ActivityTab = ({ activityData }) => {
    const activitySummaryData = activityData.map((entry) => ({
        date: format(parseISO(entry.metadata.start_time), 'yyyy-MM-dd'),
        totalCalories: Number((entry.calories_data?.total_burned_calories || 0).toFixed(0)),
        distanceKm: Number(((entry.distance_data?.distance_meters || 0) / 1000).toFixed(2)),
        activityMinutes: Number(((entry.active_durations_data?.activity_seconds || 0) / 60).toFixed(2)),
        avgHeartRate: Number((entry.heart_rate_data?.avg_hr_bpm || 0).toFixed(2)),
        maxHeartRate: Number((entry.heart_rate_data?.max_hr_bpm || 0).toFixed(2)),
        avgSpeed: Number((entry.movement_data?.avg_speed_meters_per_second
            ? (entry.movement_data.avg_speed_meters_per_second * 3.6)
            : 0).toFixed(2)),
        avgCadence: Number((entry.movement_data?.avg_cadence_rpm || 0).toFixed(2)),
        avgPace: Number((entry.movement_data?.avg_pace_minutes_per_kilometer || 0).toFixed(2)),
    }));

    // Helper function to check if a data key has non-zero values
    const hasNonZeroValues = (dataKey) => {
        return activitySummaryData.some(entry => entry[dataKey] > 0);
    };

    return (
        <DashboardCard title="Activity Over Time" icon={<Dumbbell className="text-orange-500" />}>
            {activitySummaryData.length > 0 ? (
                <>
                    {/* Calories Burned Bar Chart */}
                    {hasNonZeroValues('totalCalories') && (
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={activitySummaryData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis label={{ value: 'Calories', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <Bar dataKey="totalCalories" name="Calories Burned" fill="#ff7300" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    )}

                    {/* Distance and Activity Time Line Chart */}
                    {(hasNonZeroValues('distanceKm') || hasNonZeroValues('activityMinutes')) && (
                        <div className="mt-4">
                            <h4 className="text-lg font-medium mb-2">Distance and Activity Time</h4>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            yAxisId="left"
                                            label={{ value: 'Distance (km)', angle: -90, position: 'insideLeft' }}
                                        />
                                        <YAxis
                                            yAxisId="right"
                                            orientation="right"
                                            label={{ value: 'Activity (mins)', angle: 90, position: 'insideRight' }}
                                        />
                                        <Tooltip />
                                        {hasNonZeroValues('distanceKm') && (
                                            <Line
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey="distanceKm"
                                                name="Distance (km)"
                                                stroke="#8884d8"
                                            />
                                        )}
                                        {hasNonZeroValues('activityMinutes') && (
                                            <Line
                                                yAxisId="right"
                                                type="monotone"
                                                dataKey="activityMinutes"
                                                name="Activity Time (mins)"
                                                stroke="#82ca9d"
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}

                    {/* Heart Rate Line Chart */}
                    {(hasNonZeroValues('avgHeartRate') || hasNonZeroValues('maxHeartRate')) && (
                        <div className="mt-4">
                            <h4 className="text-lg font-medium mb-2">Heart Rate</h4>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis label={{ value: 'Heart Rate (bpm)', angle: -90, position: 'insideLeft' }} />
                                        <Tooltip />
                                        {hasNonZeroValues('avgHeartRate') && (
                                            <Line
                                                type="monotone"
                                                dataKey="avgHeartRate"
                                                name="Avg Heart Rate"
                                                stroke="#ff0000"
                                            />
                                        )}
                                        {hasNonZeroValues('maxHeartRate') && (
                                            <Line
                                                type="monotone"
                                                dataKey="maxHeartRate"
                                                name="Max Heart Rate"
                                                stroke="#ff7f7f"
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}

                    {/* Movement Data Line Charts */}
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Average Speed Chart */}
                        {hasNonZeroValues('avgSpeed') && (
                            <div className="h-64">
                                <h4 className="text-lg font-medium mb-2">Average Speed</h4>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            label={{ value: 'Speed (km/h)', angle: -90, position: 'insideLeft' }}
                                        />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey="avgSpeed"
                                            name="Avg Speed (km/h)"
                                            stroke="#00bfff"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )}

                        {/* Average Cadence Chart */}
                        {hasNonZeroValues('avgCadence') && (
                            <div className="h-64">
                                <h4 className="text-lg font-medium mb-2">Average Cadence</h4>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            label={{ value: 'Cadence (RPM)', angle: -90, position: 'insideLeft' }}
                                        />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey="avgCadence"
                                            name="Avg Cadence (RPM)"
                                            stroke="#8a2be2"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </div>

                    {/* Average Pace Chart */}
                    {hasNonZeroValues('avgPace') && (
                        <div className="mt-4">
                            <h4 className="text-lg font-medium mb-2">Average Pace</h4>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={activitySummaryData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            reversed
                                            label={{ value: 'Pace (min/km)', angle: -90, position: 'insideLeft' }}
                                        />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey="avgPace"
                                            name="Avg Pace (min/km)"
                                            stroke="#ffa500"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <p>No activity data available.</p>
            )}
        </DashboardCard>
    );
};

export default ActivityTab;
