import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../services/supabaseClient';
import { FiEdit, FiSave, FiX, FiUser, FiMail, FiCalendar, FiPhone, FiUpload, FiTrash } from 'react-icons/fi';
import defaultAvatar from '../../assets/default-avatar.png';

const Profile = ({ userEmail }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    full_name: '',
    username: '',
    dob: '',
    gender: '',
    phone: '',
    mental_health_history: '',
    current_medications: ''
  });
  const [uploadingImage, setUploadingImage] = useState(false);

  const fetchProfile = useCallback(async () => {
    if (!userEmail) {
      return;
    }

    try {
      setLoading(true);
      const { data: userData, error: userError } = await supabase.auth.getUser();

      if (userError) throw userError;

      if (!userData.user) {
        throw new Error('No authenticated user found');
      }

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userData.user.id)
        .single();

      if (error) throw error;

      setProfile(data);
      setFormData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to load profile. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { error } = await supabase
        .from('users')
        .update(formData)
        .eq('email', userEmail);

      if (error) throw error;

      setProfile(formData);
      setIsEditing(false);
      setError(null);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    try {
      setUploadingImage(true);
      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${profile.id}/${fileName}`;

      let { error: uploadError } = await supabase.storage
        .from('user_profile_pictures')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('user_profile_pictures')
        .getPublicUrl(filePath);

      if (urlError) {
        throw urlError;
      }

      setFormData(prev => ({ ...prev, profile_picture_url: publicUrl }));
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image: ' + error.message);
    } finally {
      setUploadingImage(false);
    }
  };

  const handleRemoveImage = () => {
    setFormData(prev => ({ ...prev, profile_picture_url: null }));
  };

  if (loading) return <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
  </div>;

  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-primary">My Profile</h2>
        {!isEditing ? (
          <button
            onClick={() => setIsEditing(true)}
            className="btn-secondary flex items-center"
          >
            <FiEdit className="mr-2" /> Edit Profile
          </button>
        ) : null}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-1">
          <div className="relative w-32 h-32 mx-auto mb-4">
            <img
              src={formData.profile_picture_url || defaultAvatar}
              alt="Profile"
              className="rounded-full w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultAvatar;
              }}
            />
            {isEditing && (
              <div className="absolute bottom-0 right-0 flex">
                <label htmlFor="profile-picture-upload" className="cursor-pointer bg-primary text-white rounded-full p-2 mr-2">
                  <FiUpload />
                </label>
                <input
                  id="profile-picture-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <button onClick={handleRemoveImage} className="bg-red-500 text-white rounded-full p-2">
                  <FiTrash />
                </button>
              </div>
            )}
          </div>
          {uploadingImage && <p className="text-center text-sm text-gray-500">Uploading...</p>}
        </div>
        <div className="md:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ProfileItem icon={<FiUser />} label="Full Name" value={isEditing ? formData.full_name : profile.full_name} isEditing={isEditing} name="full_name" onChange={handleChange} />
            <ProfileItem icon={<FiMail />} label="Email" value={profile.email} isEditing={false} />
            <ProfileItem icon={<FiCalendar />} label="Date of Birth" value={isEditing ? formData.dob : profile.dob} isEditing={isEditing} name="dob" onChange={handleChange} type="date" />
            <ProfileItem icon={<FiUser />} label="Gender" value={isEditing ? formData.gender : profile.gender} isEditing={isEditing} name="gender" onChange={handleChange} />
            <ProfileItem icon={<FiPhone />} label="Phone" value={isEditing ? formData.phone : profile.phone} isEditing={isEditing} name="phone" onChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h3 className="text-xl font-semibold text-primary mb-2">Mental Health History</h3>
        {isEditing ? (
          <textarea
            name="mental_health_history"
            value={formData.mental_health_history}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
          />
        ) : (
          <p className="text-gray-700">{profile.mental_health_history || 'Not provided'}</p>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-xl font-semibold text-primary mb-2">Current Medications</h3>
        {isEditing ? (
          <textarea
            name="current_medications"
            value={formData.current_medications}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
          />
        ) : (
          <p className="text-gray-700">{profile.current_medications || 'Not provided'}</p>
        )}
      </div>

      {isEditing && (
        <div className="mt-6 flex justify-end space-x-4">
          <button onClick={() => setIsEditing(false)} className="btn-secondary flex items-center">
            <FiX className="mr-2" /> Cancel
          </button>
          <button onClick={handleSubmit} className="btn-primary flex items-center">
            <FiSave className="mr-2" /> Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

const ProfileItem = ({ icon, label, value, isEditing, name, onChange, type = "text" }) => (
  <div className="flex items-center">
    <div className="text-primary mr-2">{icon}</div>
    <div className="flex-grow">
      <span className="font-semibold">{label}:</span>
      {isEditing ? (
        <input
          type={type}
          name={name}
          value={value || ''}
          onChange={onChange}
          className="w-full p-1 border rounded ml-2"
        />
      ) : (
        <span className="ml-2">{value || 'N/A'}</span>
      )}
    </div>
  </div>
);

export default Profile;