import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

const PendingApproval = ({ setIsOnboarding }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/therapist-login');
      }
    };
    checkAuth();
    return () => setIsOnboarding(false);
  }, [navigate, setIsOnboarding]);

  const handleReturnHome = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
        <h2 className="text-3xl font-bold text-primary mb-6">Application Submitted</h2>
        <div className="mb-6">
          <svg className="mx-auto h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <p className="text-gray-600 mb-4">
          Thank you for submitting your application to join Oasys as a therapist. Your application is now pending review by our admin team.
        </p>
        <p className="text-gray-600 mb-4">
          We typically process applications within 1-2
          business days. You will receive an email notification once your application has been reviewed.
        </p>
        <p className="text-gray-600 mb-6">
          If you have any questions or need to update your application, please contact our support team.
        </p>
        <button onClick={handleReturnHome} className="btn-primary inline-block">
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default PendingApproval;