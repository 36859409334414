export const therapyTypes = [
    "Cognitive Behavioral Therapy (CBT)",
    "Psychodynamic Therapy",
    "Humanistic Therapy",
    "Dialectical Behavior Therapy (DBT)",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
    "Family Therapy",
    "Group Therapy",
    "Mindfulness-based Therapy",
    "Solution-Focused Brief Therapy (SFBT)",
    "Narrative Therapy",
    "Art Therapy",
    "Play Therapy",
    "Exposure Therapy",
    "Acceptance and Commitment Therapy (ACT)",
    "Interpersonal Therapy",
    "Motivational Interviewing",
    "Psychoanalysis",
    "Gestalt Therapy",
    "Existential Therapy",
    "Rational Emotive Behavior Therapy (REBT)"
  ];
  
  export const ageGroups = [
    "Children (3-12)",
    "Adolescents (13-17)",
    "Young Adults (18-25)",
    "Adults (26-64)",
    "Seniors (65+)"
  ];
  
  export const insurancePlans = [
    "Aetna",
    "Blue Cross Blue Shield",
    "Cigna",
    "UnitedHealthcare",
    "Medicare",
    "Medicaid",
    "Humana",
    "Kaiser Permanente",
    "Anthem",
    "Centene",
    "Molina Healthcare",
    "WellCare",
    "Highmark",
    "Health Net",
    "Tricare",
    "Out-of-Network",
    "Sliding Scale"
  ];