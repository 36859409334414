import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import GetStartedPage from './pages/GetStartedPage';
import Auth from './pages/Login';
import UserDashboard from './pages/UserDashboard';
import AdminDashboard from './pages/AdminDashboard';
import TherapistDashboard from './pages/TherapistDashboard';
import TherapistSignUp from './pages/TherapistOnboarding/TherapistSignUp';
import ProfessionalInfo from './pages/TherapistOnboarding/ProfessionalInfo';
import PracticeDetails from './pages/TherapistOnboarding/PracticeDetails';
import BioAndApproach from './pages/TherapistOnboarding/BioAndApproach';
import VerificationSubmission from './pages/TherapistOnboarding/VerificationSubmission';
import PendingApproval from './pages/TherapistOnboarding/PendingApproval';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import useAuth from './hooks/useAuth';
import NavbarWrapper from './components/NavbarWrapper';
import UserSignUp from './pages/UserSignUp';
import UserLogin from './pages/Login';

function App() {
  const { isAuthenticated, setIsAuthenticated, userProfile, therapistProfile, setTherapistProfile, handleLogout } = useAuth();
  const [isOnboarding, setIsOnboarding] = useState(false);

  return (
    <>
      <NavbarWrapper
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
        isOnboarding={isOnboarding}
        userProfile={userProfile}
        therapistProfile={therapistProfile}
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/get-started" element={<GetStartedPage />} />
        <Route path="/user-signup" element={<UserSignUp />} />  
        <Route path="/login" element={<UserLogin setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/admin" element={<Auth isAdmin={true} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/dashboard" element={<UserDashboard isAuthenticated={isAuthenticated} />} />
        <Route path="/admin-dashboard" element={
          isAuthenticated ? <AdminDashboard isAuthenticated={isAuthenticated} /> : <Navigate to="/admin" replace />
        } />
        <Route path="/therapist" element={
          <TherapistDashboard 
            isAuthenticated={isAuthenticated} 
            setIsAuthenticated={setIsAuthenticated}
            therapistProfile={therapistProfile}
            setTherapistProfile={setTherapistProfile}
          />
        } />
        <Route path="/therapist-signup" element={<TherapistSignUp setIsOnboarding={setIsOnboarding} />} />
        <Route path="/therapist-onboarding/professional-info" element={<ProfessionalInfo setIsOnboarding={setIsOnboarding} />} />
        <Route path="/therapist-onboarding/practice-details" element={<PracticeDetails setIsOnboarding={setIsOnboarding} />} />
        <Route path="/therapist-onboarding/bio-and-approach" element={<BioAndApproach setIsOnboarding={setIsOnboarding} />} />
        <Route path="/therapist-onboarding/verification-submission" element={<VerificationSubmission setIsOnboarding={setIsOnboarding} />} />
        <Route path="/therapist-onboarding/pending-approval" element={<PendingApproval setIsOnboarding={setIsOnboarding} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  );
}

export default App;
