import React, { useState, useEffect, useCallback, useMemo } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { api } from '../../services/api';
import { debounce } from 'lodash';
import WellbeingScoreBar from './WellbeingScore/WellbeingScoreBar';
import PatientDetails from './PatientDetails';
import SessionEditForm from './Sessions/SessionEditForm';

const PatientList = ({ patients, onPatientAction }) => {
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(null);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [approvedPatients, setApprovedPatients] = useState([]);
  const [patientNotes, setPatientNotes] = useState([]);
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingNote, setEditingNote] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSessions, setTotalSessions] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;
  const [isRegeneratingSummary, setIsRegeneratingSummary] = useState(false);
  const [showWellbeingGraph, setShowWellbeingGraph] = useState(false);

  useEffect(() => {
    const sortedPatients = [...patients].sort((a, b) => a.full_name.localeCompare(b.full_name));
    const pending = sortedPatients.filter(patient => patient.status === 'pending');
    const approved = sortedPatients.filter(patient => patient.status === 'approved');
    setPendingPatients(pending);
    setApprovedPatients(approved);
  }, [patients]);

  const fetchPatientDetails = useCallback(async (patientId, page) => {
    setIsLoading(true);
    try {
      const { userData, therapistPatientData, sessionsData, totalSessions } = await api.patients.fetchPatientDetails(patientId, page, pageSize);
      setSelectedPatientDetails({ ...userData, ...therapistPatientData });
      setPatientNotes(prevNotes => [...prevNotes, ...sessionsData]);
      setTotalSessions(totalSessions);
    } catch (error) {
      console.error('Error fetching patient details:', error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setSelectedPatientDetails, setPatientNotes, setTotalSessions]);

  const handlePatientClick = useCallback((patient) => {
    setSelectedPatientId(patient.id);
    setPatientNotes([]);
    setCurrentPage(1);
    fetchPatientDetails(patient.id, 1);
  }, [fetchPatientDetails]);

  const loadMoreSessions = useCallback(() => {
    if (isLoading || currentPage * pageSize >= totalSessions) return;
    const nextPage = currentPage + 1;
    fetchPatientDetails(selectedPatientId, nextPage);
    setCurrentPage(nextPage);
  }, [currentPage, fetchPatientDetails, isLoading, selectedPatientId, totalSessions, pageSize]);

  const debouncedLoadMore = useCallback(debounce(loadMoreSessions, 300), [loadMoreSessions]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      debouncedLoadMore();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [debouncedLoadMore]);

  const handlePatientAction = useCallback((patientId, action) => {
    return async () => {
      try {
        await onPatientAction(patientId, action);
        await api.patients.updatePatientStatus(patientId, action === 'approve' ? 'approved' : 'denied');

        // Update the local state after successful action
        setPendingPatients(prevPending => prevPending.filter(patient => patient.id !== patientId));
        if (action === 'approve') {
          setApprovedPatients(prevApproved => [
            ...prevApproved,
            patients.find(patient => patient.id === patientId)
          ]);
        }

        // If the selected patient was acted upon, update selectedPatientDetails
        if (selectedPatientId === patientId) {
          setSelectedPatientDetails(prev => ({
            ...prev,
            status: action === 'approve' ? 'approved' : 'denied'
          }));
        }

        // If the action was 'deny' and it was the selected patient, clear the selection
        if (action === 'deny' && selectedPatientId === patientId) {
          setSelectedPatientId(null);
          setSelectedPatientDetails(null);
          setPatientNotes([]);
        }
      } catch (error) {
        console.error('Error updating patient status:', error);
      }
    };
  }, [onPatientAction, api.patients, patients, selectedPatientId, setPendingPatients, setApprovedPatients, setSelectedPatientDetails, setSelectedPatientId, setPatientNotes]);

  const toggleNoteExpansion = useCallback((noteId) => {
    setExpandedNoteId(expandedNoteId === noteId ? null : noteId);
  }, [expandedNoteId]);

  const handleEditClick = useCallback((session) => {
    setEditingNote({
      id: session.id,
      therapist_id: session.therapist_id,
      patient_id: session.patient_id, // Make sure this is included
      session_number: session.session_number,
      date: session.date,
      time: session.time,
      status: session.status,
      format: session.format,
      content: session.content,
      summary: session.summary
    });
  }, []);

  const handleEditChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditingNote(prevNote => ({
      ...prevNote,
      [name]: value
    }));
  }, []);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const sessionPayload = {
        therapist_id: editingNote.therapist_id,
        patient_id: editingNote.patient_id,
        session_number: parseInt(editingNote.session_number),
        date: editingNote.date,
        time: editingNote.time,
        status: editingNote.status || 'completed', // Provide a default status if it's undefined
        content: editingNote.content,
        format: editingNote.format,
        summary: editingNote.summary,
      };

      // Check if patient_id is present
      if (!sessionPayload.patient_id) {
        throw new Error('Patient ID is missing. Cannot update session.');
      }

      const updatedSession = await api.sessions.updateSession(editingNote.id, sessionPayload);
      setPatientNotes(prevNotes => 
        prevNotes.map(note => note.id === updatedSession.id ? updatedSession : note)
      );
      setEditingNote(null);
    } catch (error) {
      console.error('Error updating session:', error);
      alert(`Error updating session: ${error.message || 'Unknown error occurred'}`);
    }
  };

  const handleRegenerateSummary = async () => {
    if (!editingNote) return;
    setIsRegeneratingSummary(true);
    try {
      const { summary } = await api.sessions.regenerateSummary(editingNote.id, editingNote.content, editingNote.format);
      setEditingNote(prevNote => ({ ...prevNote, summary }));
    } catch (error) {
      console.error('Error regenerating summary:', error);
      alert(`Error regenerating summary: ${error.message || 'Unknown error occurred'}`);
    } finally {
      setIsRegeneratingSummary(false);
    }
  };

  const handleDeleteClick = useCallback(async (sessionId) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        await api.sessions.deleteSession(sessionId);
        setPatientNotes(prevNotes => prevNotes.filter(session => session.id !== sessionId));
        setTotalSessions(prevTotal => prevTotal - 1);
      } catch (error) {
        console.error('Error deleting session:', error);
        alert(`Failed to delete session: ${error.message || 'Unknown error occurred'}`);
      }
    }
  }, []);

  const downloadPDF = useCallback((session) => {
    const input = document.getElementById(`session-${session.id}`);
    if (!input) {
      console.error('Session element not found');
      return;
    }

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`session_${session.id}.pdf`);
    }).catch(error => {
      console.error('Error generating PDF:', error);
    });
  }, []);

  const filteredPatients = [...pendingPatients, ...approvedPatients].filter(patient =>
    patient.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedSessions = useMemo(() => {
    return [...patientNotes].sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.time}`);
      const dateB = new Date(`${b.date}T${b.time}`);
      return dateB - dateA; // Sort in descending order (latest first)
    });
  }, [patientNotes]);

  const sessionsWithWellbeingScore = useMemo(() => 
    sortedSessions.filter(session => session.wellbeing_score !== null),
    [sortedSessions]
  );

  return (
    <div className="w-full bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex h-[calc(100vh-64px)]"> 
        <div className="w-1/3 border-r border-gray-200 overflow-y-auto">
          <div className="p-4">
            <h3 className="text-2xl font-bold text-primary mb-4">Patients</h3>
            <input
              type="text"
              placeholder="Search patients..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary mb-4"
            />
            <ul className="space-y-2">
              {filteredPatients.map(patient => (
                <li
                  key={patient.id}
                  className={`p-2 rounded-md cursor-pointer transition-colors duration-150 ease-in-out ${selectedPatientId === patient.id
                    ? 'bg-primary text-white'
                    : 'hover:bg-gray-100'
                    }`}
                  onClick={() => handlePatientClick(patient)}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{patient.full_name}</span>
                    {patient.status === 'pending' && (
                      <div className="flex space-x-2">
                        <button
                          onClick={(e) => { e.stopPropagation(); handlePatientAction(patient.id, 'approve')(); }}
                          className="text-xs bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 transition-colors duration-150"
                        >
                          Approve
                        </button>
                        <button
                          onClick={(e) => { e.stopPropagation(); handlePatientAction(patient.id, 'deny')(); }}
                          className="text-xs bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition-colors duration-150"
                        >
                          Deny
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-2/3 p-4 overflow-y-auto">
          {selectedPatientDetails ? (
            <>
              <PatientDetails
                patient={selectedPatientDetails}
                showWellbeingGraph={showWellbeingGraph}
                setShowWellbeingGraph={setShowWellbeingGraph}
                sessionsWithWellbeingScore={sessionsWithWellbeingScore}
              />
              <div className="mb-6">
                <h4 className="text-lg font-medium text-gray-700 mb-2">Sessions</h4>
                {sortedSessions.length > 0 ? (
                  <div className="space-y-4">
                    {sortedSessions.map((session) => (
                      <div key={session.id} id={`session-${session.id}`} className="bg-gray-50 shadow-md rounded-lg p-4 mb-4 transition-all duration-200 hover:shadow-lg border-l-4 border-primary">
                        {editingNote && editingNote.id === session.id ? (
                          <SessionEditForm
                            editingNote={editingNote}
                            handleEditChange={handleEditChange}
                            handleEditSubmit={handleEditSubmit}
                            setEditingNote={setEditingNote}
                            handleRegenerateSummary={handleRegenerateSummary}
                            isRegeneratingSummary={isRegeneratingSummary}
                          />
                        ) : (
                          <>
                            <div className="flex justify-between items-center mb-2">
                              <span className="font-semibold text-lg text-gray-800">Session {session.session_number}</span>
                              <span className="text-sm text-gray-500">
                                {new Date(`${session.date}T${session.time}`).toLocaleString()}
                              </span>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                              <button
                                onClick={() => toggleNoteExpansion(session.id)}
                                className="text-primary hover:underline focus:outline-none transition-colors duration-200"
                              >
                                {expandedNoteId === session.id ? 'Hide Details' : 'Show Details'}
                              </button>
                              {session.wellbeing_score !== null && (
                                <WellbeingScoreBar score={session.wellbeing_score} />
                              )}
                            </div>
                            {expandedNoteId === session.id && (
                              <div className="mt-4 space-y-4">
                                <div>
                                  <h5 className="font-medium text-gray-700 mb-2">Enhanced Summary:</h5>
                                  <div
                                    className="text-sm prose prose-sm max-w-none bg-white p-4 rounded-md shadow-inner"
                                    dangerouslySetInnerHTML={{ __html: session.summary }}
                                  />
                                </div>
                                <div>
                                  <h5 className="font-medium text-gray-700 mb-2">Original Notes:</h5>
                                  <p className="text-sm bg-white p-4 rounded-md shadow-inner">{session.content}</p>
                                </div>
                                {session.file_url && (
                                  <div>
                                    <h5 className="font-medium text-gray-700 mb-2">Uploaded File:</h5>
                                    {session.file_url.toLowerCase().endsWith('.pdf') ? (
                                      <embed
                                        src={session.file_url}
                                        type="application/pdf"
                                        width="100%"
                                        height="500px"
                                        className="border border-gray-300 rounded-md"
                                      />
                                    ) : session.file_url.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                      <img
                                        src={session.file_url}
                                        alt="Uploaded file"
                                        className="max-w-full h-auto rounded-md shadow-md"
                                      />
                                    ) : (
                                      <a
                                        href={session.file_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline"
                                      >
                                        View uploaded file
                                      </a>
                                    )}
                                  </div>
                                )}
                                <div className="flex space-x-2 mt-4">
                                  <button
                                    onClick={() => handleEditClick(session)}
                                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200 text-sm"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteClick(session.id)}
                                    className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors duration-200 text-sm"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    onClick={() => downloadPDF(session)}
                                    className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-200 text-sm"
                                  >
                                    Download as PDF
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                    {isLoading && <p>Loading more sessions...</p>}
                  </div>
                ) : (
                  <p>No sessions available for this patient.</p>
                )}
              </div>
            </>
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-gray-500 text-center text-lg">Select a patient to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientList;