import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import OnboardingProgress from '../../components/Onboarding/TherapistSignupBar';

const ProfessionalInfo = ({ isAuthenticated, setIsOnboarding }) => {
  const [formData, setFormData] = useState({
    licenseType: '',
    licenseNumber: '',
    yearsOfExperience: '',
    specializations: [],
    languages: [],
    education: []
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOnboarding(true);
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/therapist-login');
      } else {
        // Fetch existing therapist data
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          setFormData({
            licenseType: data.license_type || '',
            licenseNumber: data.license_number || '',
            yearsOfExperience: data.years_of_experience || '',
            specializations: data.specializations || [],
            languages: data.languages || [],
            education: data.education || []
          });
        }
      }
    };
    checkAuth();
    return () => setIsOnboarding(false);
  }, [navigate, setIsOnboarding]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value.split(',').map(item => item.trim())
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('therapists')
        .update({
          license_type: formData.licenseType,
          license_number: formData.licenseNumber,
          years_of_experience: parseInt(formData.yearsOfExperience),
          specializations: formData.specializations,
          languages: formData.languages,
          education: formData.education
        })
        .eq('id', user.id);

      if (error) throw error;

      navigate('/therapist-onboarding/practice-details');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-primary mb-6 text-center">Professional Information</h2>
        <OnboardingProgress currentStep="/therapist-onboarding/professional-info" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="licenseType" className="block text-sm font-medium text-gray-700 mb-1">License Type *</label>
            <input
              type="text"
              id="licenseType"
              name="licenseType"
              value={formData.licenseType}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="licenseNumber" className="block text-sm font-medium text-gray-700 mb-1">License Number *</label>
            <input
              type="text"
              id="licenseNumber"
              name="licenseNumber"
              value={formData.licenseNumber}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="yearsOfExperience" className="block text-sm font-medium text-gray-700 mb-1">Years of Experience *</label>
            <input
              type="number"
              id="yearsOfExperience"
              name="yearsOfExperience"
              value={formData.yearsOfExperience}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="specializations" className="block text-sm font-medium text-gray-700 mb-1">Specializations (comma-separated) *</label>
            <input
              type="text"
              id="specializations"
              name="specializations"
              value={formData.specializations.join(', ')}
              onChange={handleArrayChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="languages" className="block text-sm font-medium text-gray-700 mb-1">Languages (comma-separated) *</label>
            <input
              type="text"
              id="languages"
              name="languages"
              value={formData.languages.join(', ')}
              onChange={handleArrayChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="education" className="block text-sm font-medium text-gray-700 mb-1">Education (comma-separated) *</label>
            <input
              type="text"
              id="education"
              name="education"
              value={formData.education.join(', ')}
              onChange={handleArrayChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <button type="submit" className="w-full btn-primary">Next</button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default ProfessionalInfo;