import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import UserDashboardContent from './UserDashboardContent';

const UserDashboard = ({ setIsAuthenticated }) => {
  const [userEmail, setUserEmail] = useState('');
  const [activeSection, setActiveSection] = useState('overview');
  const [authMessage, setAuthMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAuthChange = useCallback((isAuthenticated) => {
    if (typeof setIsAuthenticated === 'function') {
      setIsAuthenticated(isAuthenticated);
    }
  }, [setIsAuthenticated]);

  useEffect(() => {
    const fetchUserData = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error);
        handleAuthChange(false);
        navigate('/login');
        return;
      }
      if (data.session) {
        handleAuthChange(true);
        const { user } = data.session;
        setUserEmail(user.email);
      } else {
        handleAuthChange(false);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate, handleAuthChange]);

  useEffect(() => {
    if (location.state && location.state.showProfile) {
      setActiveSection('profile');
    }

    // Parse the query parameters to check for auth success or failure
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const authStatus = params.get('auth');
      if (authStatus === 'success') {
        setAuthMessage('Health data provider connected successfully!');
      } else if (authStatus === 'failure') {
        const error = params.get('error');
        const errorDescription = params.get('error_description');
        setAuthMessage(`Failed to connect health data provider: ${errorDescription || 'Unknown error'}`);
        console.error(`Auth failure: ${error}: ${errorDescription}`);
      }
    }
  }, [location]);

  const handleSessionExpiration = () => {
    handleAuthChange(false);
    navigate('/login');
  };

  return (
    <div>
      <UserDashboardContent
        userEmail={userEmail}
        onSessionExpired={handleSessionExpiration}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        authMessage={authMessage}
        setAuthMessage={setAuthMessage}
      />
    </div>
  );
};

export default UserDashboard;
