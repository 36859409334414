import React from 'react';

export const DashboardCard = ({ title, icon, children }) => (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex items-center mb-4">
            {icon}
            <h3 className="text-xl font-semibold ml-2">{title}</h3>
        </div>
        {children}
    </div>
);

export const getStatusColor = (value, thresholds, reverse = false) => {
    if (reverse) {
        if (value <= thresholds.good) return 'text-green-500';
        if (value <= thresholds.average) return 'text-yellow-500';
        return 'text-red-500';
    }
    if (value >= thresholds.good) return 'text-green-500';
    if (value >= thresholds.average) return 'text-yellow-500';
    return 'text-red-500';
};

export const round = (num) => Number(num).toFixed(2);