import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TherapistProfile from '../components/TherapistDashboard/TherapistProfile';
import PatientList from '../components/TherapistDashboard/PatientList';
import { supabase } from '../services/supabaseClient';
import SessionPanel from '../components/TherapistDashboard/Sessions/SessionPanel'; 

const TherapistDashboard = ({ isAuthenticated, setIsAuthenticated, therapistProfile, setTherapistProfile }) => {
  const [activeSection, setActiveSection] = useState('overview');
  const [patients, setPatients] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTherapistProfile = useCallback(async (userId) => {
    try {
      const { data: therapistData, error } = await supabase
        .from('therapists')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('Error fetching therapist profile:', error);
        return;
      }

      if (therapistData) {
        setTherapistProfile(therapistData);
      }
    } catch (error) {
      console.error('Unexpected error in fetchTherapistProfile:', error);
    }
  }, [setTherapistProfile]);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setCurrentUser(user);
        setIsAuthenticated(true);
        fetchTherapistProfile(user.id);

        // Check therapist status
        const { data: therapistData, error } = await supabase
          .from('therapists')
          .select('status')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
          navigate('/therapist-login');
          return;
        }

        if (!therapistData) {
          console.log('User is not a therapist');
          navigate('/therapist-login');
          return;
        }

        // If not approved, redirect to pending approval page
        if (therapistData.status !== 'approved') {
          navigate('/therapist-onboarding/pending-approval');
        }
      } else {
        navigate('/therapist-login');
      }
    };

    checkAuth();
  }, [navigate, setIsAuthenticated, fetchTherapistProfile]);

  const fetchPatients = useCallback(async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const { data: patientsData, error } = await supabase
        .from('therapist_patients')
        .select(`
          patient_id,
          status,
          users:patient_id (id, full_name, email)
        `)
        .eq('therapist_id', session.user.id);

      if (error) throw error;

      setPatients(patientsData.map(patient => ({
        id: patient.patient_id,
        full_name: patient.users.full_name,
        email: patient.users.email,
        status: patient.status === true ? "approved" : (patient.status === false ? "denied" : "pending")
      })));
    } catch (err) {
      console.error('Error fetching patients:', err);
    }
  }, []);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  const [approvedPatients, setApprovedPatients] = useState([]);

  useEffect(() => {
    const filteredApprovedPatients = patients.filter(patient => patient.status === 'approved');
    setApprovedPatients(filteredApprovedPatients);
  }, [patients]);

  const handleTabChange = (newSection) => {
    setActiveSection(newSection);
  };

  const handlePatientAction = async (patientId, action) => {
    if (action === 'deny') {
      const confirmDeny = window.confirm("Are you sure you want to deny this patient? They will be removed from your patient list.");
      if (!confirmDeny) return;
    }

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      let updateData;
      if (action === 'approve') {
        updateData = { status: true };
      } else if (action === 'deny') {
        updateData = { status: false };
      } else {
        return;
      }

      const { error } = await supabase
        .from('therapist_patients')
        .update(updateData)
        .eq('therapist_id', session.user.id)
        .eq('patient_id', patientId);

      if (error) throw error;

      if (action === 'deny') {
        setPatients(prevPatients => prevPatients.filter(patient => patient.id !== patientId));
      } else {
        setPatients(prevPatients =>
          prevPatients.map(patient =>
            patient.id === patientId
              ? { ...patient, status: 'approved' }
              : patient
          )
        );
      }

      console.log(`Patient ${action}d successfully`);
      await fetchPatients();

    } catch (err) {
      console.error('Error handling patient action:', err);
    }
  };

  useEffect(() => {
    if (location.state && location.state.showProfile) {
      setActiveSection('profile');
    }
  }, [location]);

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-2xl font-bold text-primary mb-4">Therapist Dashboard</h2>
          <nav className="space-y-2">
            {['overview', 'patients', 'sessions', 'profile'].map((section) => (
              <button
                key={section}
                className={`w-full text-left px-4 py-2 rounded-md transition-colors duration-150 ease-in-out ${
                  activeSection === section
                    ? 'bg-primary text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
                onClick={() => handleTabChange(section)}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex-1 overflow-auto p-8">
        {activeSection === 'overview' && (
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold text-primary mb-4">Overview</h2>
            <p className="text-gray-600">Welcome to your therapist dashboard, {therapistProfile?.full_name}.</p>
          </div>
        )}
        {activeSection === 'patients' && (
          <PatientList patients={patients} onPatientAction={handlePatientAction} />
        )}
        {activeSection === 'sessions' && (
          <SessionPanel currentUser={currentUser} patients={approvedPatients} />
        )}
        {activeSection === 'profile' && (
          <TherapistProfile therapistProfile={therapistProfile} />
        )}
      </div>
    </div>
  );
};

export default TherapistDashboard;