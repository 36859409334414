import React from 'react';
import { Link } from 'react-router-dom';
import { FiUser, FiHeart } from 'react-icons/fi';

const GetStartedPage = () => {
  return (
    <div className="bg-gradient-to-br from-background to-lightGray min-h-screen flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-2xl overflow-hidden max-w-4xl w-full mb-8 -mt-16">
        <div className="flex flex-col md:flex-row">
          <UserSection />
          <TherapistSection />
        </div>
      </div>
      <div className="text-center mt-4"> 
        <p className="text-gray-600">
          Already have an account?{' '}
          <Link to="/login" className="text-primary hover:text-secondary font-semibold transition-colors duration-300">
            Log in here
          </Link>
        </p>
      </div>
    </div>
  );
};

const UserSection = () => (
  <div className="w-full md:w-1/2 p-8 bg-gradient-to-br from-blue-50 to-indigo-50">
    <div className="text-center mb-6">
      <div className="bg-blue-100 rounded-full p-4 inline-block">
        <FiUser className="w-16 h-16 text-blue-600" />
      </div>
      <h2 className="text-3xl font-bold text-blue-800 mt-4">I'm a User</h2>
    </div>
    <p className="text-center text-gray-600 mb-6">
      Get personalized mental health support and insights.
    </p>
    <div className="space-y-4">
      <Link to="/user-signup" className="btn-primary w-full block text-center py-3 rounded-lg text-lg font-semibold transition-all duration-300 transform hover:scale-105">
        Sign Up as User
      </Link>
    </div>
  </div>
);

const TherapistSection = () => (
  <div className="w-full md:w-1/2 p-8 bg-gradient-to-br from-green-50 to-teal-50">
    <div className="text-center mb-6">
      <div className="bg-green-100 rounded-full p-4 inline-block">
        <FiHeart className="w-16 h-16 text-green-600" />
      </div>
      <h2 className="text-3xl font-bold text-green-800 mt-4">I'm a Therapist</h2>
    </div>
    <p className="text-center text-gray-600 mb-6">
      Join our platform to connect with clients and grow your practice.
    </p>
    <div className="space-y-4">
      <Link to="/therapist-signup" className="btn-secondary w-full block text-center py-3 rounded-lg text-lg font-semibold transition-all duration-300 transform hover:scale-105">
        Sign Up as Therapist
      </Link>
    </div>
  </div>
);

export default GetStartedPage;