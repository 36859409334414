import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import OnboardingProgress from '../../components/Onboarding/TherapistSignupBar';
import CodeOfEthicsModal from '../../components/Onboarding/CodeOfEthicsModal';

const VerificationSubmission = ({ setIsOnboarding }) => {
  const [licenseFile, setLicenseFile] = useState(null);
  const [agreeToEthics, setAgreeToEthics] = useState(false);
  const [agreeToBackgroundCheck, setAgreeToBackgroundCheck] = useState(false);
  const [error, setError] = useState(null);
  const [isEthicsModalOpen, setIsEthicsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOnboarding(true);
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/therapist-login');
      }
    };
    checkAuth();
    return () => setIsOnboarding(false);
  }, [navigate, setIsOnboarding]);

  const handleFileChange = (e) => {
    setLicenseFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeToEthics || !agreeToBackgroundCheck) {
      setError('Please agree to the ethics code and background check.');
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      let fileName = null;

      // Upload license file if provided
      if (licenseFile) {
        const fileExt = licenseFile.name.split('.').pop();
        fileName = `${user.id}_license.${fileExt}`;
        const { error: uploadError } = await supabase.storage
          .from('license_documents')
          .upload(fileName, licenseFile);

        if (uploadError) throw uploadError;
      }

      // Update therapist record
      const { error: updateError } = await supabase
        .from('therapists')
        .update({
          license_document: fileName,
          agreed_to_ethics: agreeToEthics,
          agreed_to_background_check: agreeToBackgroundCheck,
          status: 'pending_review'
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      navigate('/therapist-onboarding/pending-approval');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-primary mb-6 text-center">Verification and Submission</h2>
        <OnboardingProgress currentStep="/therapist-onboarding/verification-submission" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="licenseFile" className="block text-sm font-medium text-gray-700 mb-1">Upload License Document (Optional)</label>
            <input
              type="file"
              id="licenseFile"
              onChange={handleFileChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeToEthics"
              checked={agreeToEthics}
              onChange={(e) => setAgreeToEthics(e.target.checked)}
              required
              className="mr-2"
            />
            <label htmlFor="agreeToEthics" className="text-sm font-medium text-gray-700">
              I agree to abide by the{' '}
              <button
                type="button"
                onClick={() => setIsEthicsModalOpen(true)}
                className="text-primary hover:underline focus:outline-none"
              >
                professional code of ethics
              </button>{' '}
              *
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeToBackgroundCheck"
              checked={agreeToBackgroundCheck}
              onChange={(e) => setAgreeToBackgroundCheck(e.target.checked)}
              required
              className="mr-2"
            />
            <label htmlFor="agreeToBackgroundCheck" className="text-sm font-medium text-gray-700">I consent to a background check *</label>
          </div>
          <button type="submit" className="w-full btn-primary">Submit Application</button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
      <CodeOfEthicsModal isOpen={isEthicsModalOpen} onClose={() => setIsEthicsModalOpen(false)} />
    </div>
  );
};

export default VerificationSubmission;