import axios from 'axios';
import { supabase } from './supabaseClient';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const getAccessToken = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session ? session.access_token : null;
};

export const api = {
    chat: {
        sendMessage: async (userEmail, message, chatHistory) => {
            const response = await fetch(`${API_URL}/chat/${userEmail}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ message, chatHistory })
            });
            if (!response.ok) {
                throw new Error('Failed to get chat response');
            }
            return response.json();
        }
    },
    notes: {
        fetchNotes: async (therapistId, page, searchTerm) => {
            let query = supabase
                .from('notes')
                .select(`
          id,
          session_number,
          date,
          time,
          format,
          content,
          summary,  
          patient_id,
          users:patient_id (
            full_name
          )
        `)
                .eq('therapist_id', therapistId)
                .order('date', { ascending: false })
                .order('time', { ascending: false })
                .range((page - 1) * 10, page * 10 - 1);

            if (searchTerm) {
                query = query.ilike('users.full_name', `%${searchTerm}%`);
            }

            const { data, error } = await query;
            if (error) throw error;
            return data;
        },
        updateNote: async (noteId, therapistId, noteData) => {
            const { error } = await supabase
                .from('notes')
                .update(noteData)
                .eq('id', noteId)
                .eq('therapist_id', therapistId);
            if (error) throw error;
        },
        deleteNote: async (noteId) => {
            const { error } = await supabase
                .from('notes')
                .delete()
                .eq('id', noteId);
            if (error) throw error;
        },
        extractText: async (file) => {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${API_URL}/sessions/extract-text`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to extract text from file');
            }

            return response.json();
        },
        summarize: async (content, format) => {
            const response = await axios.post(
                `${API_URL}/notes/summarize`,
                { content, format },
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        },
        regenerateSummary: async (noteId, noteContent, noteFormat) => {
            const response = await fetch(`${API_URL}/notes/${noteId}/regenerate-summary`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    note_content: noteContent,
                    note_format: noteFormat
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to regenerate summary');
            }

            return response.json();
        },
        createNote: async (noteData) => {
            const { data, error } = await supabase
                .from('notes')
                .insert(noteData)
                .select()
                .single();
            if (error) throw error;
            return { data };
        }
    },
    patients: {
        fetchPatientDetails: async (patientId, page = 1, pageSize = 5) => {
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('*')
                .eq('id', patientId)
                .single();

            if (userError) throw userError;

            const { data: therapistPatientData, error: tpError } = await supabase
                .from('therapist_patients')
                .select('*')
                .eq('patient_id', patientId);

            if (tpError) throw tpError;

            const { data: sessionsData, error: sessionsError, count } = await supabase
                .from('sessions')
                .select('*', { count: 'exact' })
                .eq('patient_id', patientId)
                .order('date', { ascending: false })
                .range((page - 1) * pageSize, page * pageSize - 1);

            if (sessionsError) throw sessionsError;

            return {
                userData,
                therapistPatientData: therapistPatientData[0],
                sessionsData,
                totalSessions: count
            };
        },
        fetchPatients: async (therapistId) => {
            const { data, error } = await supabase
                .from('therapist_patients')
                .select(`
                patient_id,
                users:patient_id (
                  id,
                  full_name,
                  email,
                  status
                )
              `)
                .eq('therapist_id', therapistId);

            if (error) throw error;
            return data.map(item => item.users);
        },
        updatePatientStatus: async (patientId, status) => {
            const { error } = await supabase
                .from('users')
                .update({ status })
                .eq('id', patientId);
            if (error) throw error;
        }
    },
    files: {
        uploadFile: async (userId, file) => {
            try {
                const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
                const filePath = `${userId}/${fileName}`;
                const { error } = await supabase.storage
                    .from('notes')
                    .upload(filePath, file);

                if (error) {
                    console.error('Upload error:', error);
                    throw error;
                }

                const { data: { publicUrl } } = supabase.storage
                    .from('notes')
                    .getPublicUrl(filePath);

                return publicUrl;
            } catch (error) {
                console.error('Upload error:', error);
                throw error;
            }
        }
    },
    sessions: {
        getSessions: async (userId, page = 1, searchTerm = '', pageSize = 10) => {
            let query = supabase
                .from('sessions')
                .select('*, patient:users!patient_id(full_name), wellbeing_score', { count: 'exact' })
                .eq('therapist_id', userId)
                .order('date', { ascending: false })
                .order('time', { ascending: false });

            if (searchTerm) {
                query = query.ilike('patient.full_name', `%${searchTerm}%`);
            }

            const { data, error, count } = await query
                .range((page - 1) * pageSize, page * pageSize - 1);

            if (error) throw error;
            return { data, count };
        },
        updateSession: async (sessionId, sessionData) => {
            try {
                const response = await fetch(`${API_URL}/sessions/${sessionId}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sessionData),
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error response:', errorData);
                    throw new Error(JSON.stringify(errorData));
                }
                return response.json();
            } catch (error) {
                console.error('Error in updateSession:', error);
                throw error;
            }
        },
        deleteSession: async (sessionId) => {
            try {
                const response = await fetch(`${API_URL}/sessions/${sessionId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                    },
                });

                if (response.status === 204) {
                    return { success: true, message: 'Session deleted successfully' };
                }

                if (!response.ok) {
                    const errorData = await response.json().catch(() => ({}));
                    throw new Error(errorData.detail || 'Failed to delete session');
                }

                return await response.json();
            } catch (error) {
                console.error('Error in deleteSession:', error);
                throw error;
            }
        },
        regenerateSummary: async (sessionId, content, format) => {
            const response = await fetch(`${API_URL}/sessions/${sessionId}/regenerate-summary`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content, format }),
            });
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || 'Failed to regenerate summary');
            }
            return response.json();
        },
        createSession: async (sessionData) => {
            try {
                const response = await fetch(`${API_URL}/sessions`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sessionData),
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error response:', errorData);
                    throw new Error(JSON.stringify(errorData));
                }
                const data = await response.json();
                return data;  // Return the created session data
            } catch (error) {
                console.error('Error in createSession:', error);
                throw error;
            }
        },
        summarize: async (content, format) => {
            const response = await axios.post(
                `${API_URL}/sessions/summarize`,
                { content, format },
                {
                    headers: {
                        'Authorization': `Bearer ${await getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }
    },
    zoom: {
        generateSignature: async (meetingNumber) => {
            const response = await fetch(`${API_URL}/zoom/generate-signature?meeting_number=${meetingNumber}&role=0`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data.signature;
        }
    },
    terra: {
        generateWidgetSession: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/generate-widget`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken()}`
                },
                body: JSON.stringify({ userEmail })
            });
            if (!response.ok) {
                throw new Error('Failed to generate Terra widget session');
            }
            return response.json();
        },
        getHealthData: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/health-data/${userEmail}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch health data');
            }
            const data = await response.json();
            return data;
        },
        disconnectProvider: async (userEmail, providerId) => {
            const response = await fetch(`${API_URL}/terra/disconnect/${userEmail}/${providerId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to disconnect provider');
            }
            return response.json();
        },
        getNutritionData: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/nutrition-data/${userEmail}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch nutrition data');
            }
            return response.json();
        },
        getSleepData: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/sleep-data/${userEmail}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch sleep data');
            }
            return response.json();
        },
        getActivityData: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/activity-data/${userEmail}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch activity data');
            }
            const data = await response.json();
            return data;
        },
        getConnectedProviders: async (userEmail) => {
            const response = await fetch(`${API_URL}/terra/connected-providers/${userEmail}`, {
                headers: {
                    'Authorization': `Bearer ${await getAccessToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch connected providers');
            }
            return response.json();
        },
    }
};