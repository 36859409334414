import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../services/supabaseClient';
import { FiUser, FiCheck, FiX, FiClock, FiSearch } from 'react-icons/fi';

const TherapistList = ({ onTherapistSelect, userTherapist }) => {
  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const fetchTherapists = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('therapists')
        .select(`
          id,
          full_name,
          email,
          license_type,
          license_number,
          years_of_experience,
          specializations,
          languages,
          education,
          therapy_types,
          age_groups_served,
          telehealth_available,
          insurance_accepted,
          professional_bio,
          therapy_approach,
          address,
          phone_number,
          profile_picture_url,
          status
        `)
        .order('full_name');

      if (error) throw error;
      setTherapists(data);
    } catch (err) {
      console.error('Error fetching therapists:', err);
      setError('Failed to fetch therapists. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserTherapist = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const { data, error } = await supabase
        .from('therapist_patients')
        .select(`
          status,
          therapists (*)
        `)
        .eq('patient_id', user.id);

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      if (data && data.length > 0) {
        setSelectedTherapist({ ...data[0].therapists, status: data[0].status });
        onTherapistSelect({ ...data[0].therapists, status: data[0].status });
      } else {
        console.log('No therapist found for this user');
        setSelectedTherapist(null);
        onTherapistSelect(null);
      }
    } catch (err) {
      console.error('Error fetching user therapist:', err);
      setError('Failed to fetch your therapist. Please try again.');
    }
  }, [onTherapistSelect]);

  useEffect(() => {
    fetchTherapists();
    fetchUserTherapist();
  }, [fetchTherapists, fetchUserTherapist]);

  const handleSelectTherapist = async (therapist) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const { error } = await supabase
        .from('therapist_patients')
        .upsert({
          therapist_id: therapist.id,
          patient_id: user.id,
          status: null
        }, {
          onConflict: 'therapist_id,patient_id'
        });

      if (error) throw error;

      setSelectedTherapist({ ...therapist, status: null });
      onTherapistSelect({ ...therapist, status: null });
    } catch (err) {
      console.error('Failed to select therapist:', err);
      setError('Failed to select therapist. Please try again.');
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.trim() === '') {
      setSearchResults([]);
    } else {
      const filteredTherapists = therapists.filter(therapist =>
        therapist.full_name.toLowerCase().includes(query.toLowerCase()) ||
        (therapist.specializations && 
         Array.isArray(therapist.specializations) &&
         therapist.specializations.some(spec => 
           spec.toLowerCase().includes(query.toLowerCase())
         ))
      );
      setSearchResults(filteredTherapists);
    }
  };

  const renderTherapistSummary = (therapist) => (
    <div className="bg-gray-100 p-4 rounded-lg mb-4">
      <div className="flex items-center mb-2">
        <div className="bg-primary text-white rounded-full p-2 mr-3">
          {therapist.profile_picture_url ? (
            <img src={therapist.profile_picture_url} alt={therapist.full_name} className="w-8 h-8 rounded-full" />
          ) : (
            <FiUser size={20} />
          )}
        </div>
        <div>
          <h4 className="text-lg font-semibold">{therapist.full_name}</h4>
          <p className="text-sm text-gray-600">{therapist.license_type || 'License not specified'}</p>
        </div>
      </div>
      <p className="text-sm mb-1">
        <strong>Specializations:</strong> {therapist.specializations && Array.isArray(therapist.specializations) ? therapist.specializations.slice(0, 3).join(', ') : 'Not specified'}
      </p>
      <p className="text-sm mb-2">
        <strong>Experience:</strong> {therapist.years_of_experience ? `${therapist.years_of_experience} years` : 'Not specified'}
      </p>
      <button
        onClick={() => handleSelectTherapist(therapist)}
        className="bg-primary text-white px-3 py-1 rounded-md text-sm hover:bg-primary-dark transition-colors duration-300"
      >
        Select
      </button>
    </div>
  );

  const renderExpandedTherapistInfo = (therapist) => (
    <div className="bg-gray-100 p-6 rounded-lg">
      <div className="flex items-center mb-4">
        <div className="bg-primary text-white rounded-full p-3 mr-4">
          {therapist.profile_picture_url ? (
            <img src={therapist.profile_picture_url} alt={therapist.full_name} className="w-16 h-16 rounded-full" />
          ) : (
            <FiUser size={32} />
          )}
        </div>
        <div>
          <h3 className="text-2xl font-semibold">{therapist.full_name}</h3>
          <p className="text-gray-600">{therapist.license_type} - {therapist.license_number || 'N/A'}</p>
        </div>
      </div>
      <p className="mb-2"><strong>Experience:</strong> {therapist.years_of_experience ? `${therapist.years_of_experience} years` : 'Not specified'}</p>
      <p className="mb-2"><strong>Specializations:</strong> {therapist.specializations?.join(', ') || 'Not specified'}</p>
      <p className="mb-2"><strong>Languages:</strong> {therapist.languages?.join(', ') || 'Not specified'}</p>
      <p className="mb-2"><strong>Education:</strong> {therapist.education?.join(', ') || 'Not specified'}</p>
      <p className="mb-2"><strong>Therapy Types:</strong> {therapist.therapy_types?.join(', ') || 'Not specified'}</p>
      <p className="mb-2"><strong>Age Groups:</strong> {therapist.age_groups_served?.join(', ') || 'Not specified'}</p>
      {therapist.telehealth_available && <p className="mb-2"><strong>Telehealth Available</strong></p>}
      <p className="mb-2"><strong>Accepted Insurance:</strong> {therapist.insurance_accepted?.join(', ') || 'Not specified'}</p>
      <p className="mb-4"><strong>Professional Bio:</strong> {therapist.professional_bio || 'Not provided'}</p>
      <p className="mb-2"><strong>Therapy Approach:</strong> {therapist.therapy_approach || 'Not specified'}</p>
      <p className="mb-2"><strong>Address:</strong> {therapist.address || 'Not provided'}</p>
      <p className="mb-2"><strong>Phone:</strong> {therapist.phone_number || 'Not provided'}</p>
      <div className="mt-4">
        <strong>Status:</strong>
        {therapist.status === true && (
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm ml-2">
            <FiCheck className="inline mr-1" /> Approved
          </span>
        )}
        {therapist.status === false && (
          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-full text-sm ml-2">
            <FiX className="inline mr-1" /> Denied
          </span>
        )}
        {therapist.status === null && (
          <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-sm ml-2">
            <FiClock className="inline mr-1" /> Pending Approval
          </span>
        )}
      </div>
    </div>
  );

  return (
    <>
      {selectedTherapist ? (
        <div>
          {renderExpandedTherapistInfo(selectedTherapist)}
          <button
            onClick={() => setSelectedTherapist(null)}
            className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors duration-300"
          >
            Back to Search
          </button>
        </div>
      ) : (
        <div>
          <h3 className="text-2xl font-bold text-primary mb-4">Find a Therapist</h3>
          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search by name or specialization"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center p-4">{error}</div>
          ) : searchResults.length === 0 && searchQuery.trim() !== '' ? (
            <p className="text-gray-600">No therapists found matching your search.</p>
          ) : (
            <div className="space-y-4">
              {searchResults.map(therapist => renderTherapistSummary(therapist))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TherapistList;