import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';

const NavbarWrapper = ({ isAuthenticated, handleLogout, isOnboarding, userProfile, therapistProfile }) => {
  const location = useLocation();
  const hideButtonRoutes = ['/forgot-password', '/reset-password'];
  const showButton = !hideButtonRoutes.includes(location.pathname);

  return (
    <Navbar
      isAuthenticated={isAuthenticated}
      handleLogout={handleLogout}
      isOnboarding={isOnboarding}
      showButton={showButton}
      userProfile={userProfile}
      therapistProfile={therapistProfile}
    />
  );
};

export default NavbarWrapper;