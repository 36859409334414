import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import oasysLogo from '../assets/oasys-logo.png';
import defaultAvatar from '../assets/default-avatar.png';
import { FiUser, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Navbar = ({ isAuthenticated, handleLogout, isOnboarding, showButton = true, userProfile, therapistProfile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const isTherapist = location.pathname.startsWith('/therapist');
  const profile = isTherapist ? therapistProfile : userProfile;

  const handleLogoutClick = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      try {
        await supabase.auth.signOut();
        handleLogout();
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    }
  };

  const handleLogoClick = async () => {
    if (!isAuthenticated || isOnboarding) {
      if (isOnboarding) {
        const confirmNavigation = window.confirm("Changes will not be saved. Are you sure you want to leave?");
        if (confirmNavigation) {
          navigate('/');
        }
      } else {
        navigate('/');
      }
    }
  };

  const toggleDropdown = () => setDropdownOpen(prev => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Close dropdown when location changes
  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  const handleProfileClick = () => {
    if (isTherapist) {
      navigate('/therapist', { state: { showProfile: true } });
    } else {
      navigate('/dashboard', { state: { showProfile: true } });
    }
    setDropdownOpen(false);
  };

  return (
    <nav className="bg-white shadow-sm border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-14">
          <div 
            onClick={handleLogoClick} 
            className={`flex-shrink-0 ${(!isAuthenticated || isOnboarding) ? 'cursor-pointer' : 'cursor-default'} flex items-center`}
          >
            <img src={oasysLogo} alt="Oasys Logo" className="h-14 w-auto mr-1" />
          </div>
          <div className="flex items-center">
            {isAuthenticated && profile ? (
              <div className="relative" ref={dropdownRef}>
                <div 
                  className="flex items-center cursor-pointer" 
                  onClick={toggleDropdown}
                >
                  <img
                    src={profile?.profile_picture_url || defaultAvatar}
                    alt="Profile Avatar"
                    className="h-8 w-8 rounded-full mr-2 object-cover object-center"
                  />
                  <span className="text-gray-700 hover:text-primary transition-colors duration-300">
                    {profile?.full_name || (isTherapist ? 'Therapist' : 'User')}
                  </span>
                </div>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <button
                      onClick={handleProfileClick}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <FiUser className="mr-2" /> Profile
                    </button>
                    <button
                      onClick={handleLogoutClick}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <FiLogOut className="mr-2" /> Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link
                  to="/get-started"
                  className="mr-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-primary hover:bg-secondary transition-colors duration-300"
                >
                  Get Started
                </Link>
                <Link
                  to="/login"
                  className="inline-flex items-center justify-center px-4 py-2 border border-primary rounded-md text-sm font-medium text-primary hover:bg-primary hover:text-white transition-colors duration-300"
                >
                  Log In
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;