import React from 'react';

const WellbeingScore = ({ score }) => {
  if (score === null || score === undefined) {
    return null;
  }

  const getColorClass = (score) => {
    if (score >= 80) return 'bg-green-500';
    if (score >= 60) return 'bg-yellow-500';
    if (score >= 40) return 'bg-orange-500';
    return 'bg-red-500';
  };

  return (
    <div className="flex items-center">
      <span className="mr-2 text-sm font-medium text-gray-700">Wellbeing Score:</span>
      <div className={`w-8 h-8 rounded-full flex items-center justify-center ${getColorClass(score)}`}>
        <span className="text-xs font-bold text-white">{score}</span>
      </div>
    </div>
  );
};

export default WellbeingScore;