import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';

const AdminDashboard = ({ isAuthenticated }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [pendingTherapists, setPendingTherapists] = useState([]);
  const [error, setError] = useState(null);
  const [displayData, setDisplayData] = useState('users');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const fetchDashboardData = useCallback(async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session. Please log in as an admin.');
      }

      // Fetch current user's role
      const { data: userData, error: currentUserError } = await supabase
        .from('users')
        .select('role')
        .eq('id', session.user.id)
        .single();

      if (currentUserError) throw currentUserError;

      if (userData.role !== 'admin') {
        throw new Error('Access denied. Admin privileges required.');
      }

      // Fetch users count
      const { count: usersCount, error: usersCountError } = await supabase
        .from('users')
        .select('*', { count: 'exact', head: true });

      if (usersCountError) throw usersCountError;

      // Fetch therapists count
      const { count: therapistsCount, error: therapistsCountError } = await supabase
        .from('therapists')
        .select('*', { count: 'exact', head: true });

      if (therapistsCountError) throw therapistsCountError;

      // Fetch new users in last 30 days
      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
      const { count: newUsersCount, error: newUsersError } = await supabase
        .from('users')
        .select('*', { count: 'exact', head: true })
        .gte('created_at', thirtyDaysAgo);

      if (newUsersError) throw newUsersError;

      setDashboardData({
        statistics: {
          total_users: usersCount,
          total_therapists: therapistsCount,
          new_users_last_30_days: newUsersCount
        }
      });

      await fetchTableData(displayData, 1);
      await fetchPendingTherapists();
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError(error.message);
      navigate('/admin');
    }
  }, [navigate, displayData]);

  const fetchTableData = async (dataType, pageNumber) => {
    const from = (pageNumber - 1) * itemsPerPage;
    const to = from + itemsPerPage - 1;

    try {
      let { data, error, count } = await supabase
        .from(dataType)
        .select('*', { count: 'exact' })
        .range(from, to)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setDashboardData(prev => ({
        ...prev,
        tableData: pageNumber === 1 ? data : [...prev.tableData, ...data]
      }));
      setHasMore(count > to + 1);
      setPage(pageNumber);
    } catch (error) {
      console.error(`Error fetching ${dataType}:`, error);
      setError(error.message);
    }
  };

  const fetchPendingTherapists = async () => {
    try {
      const { data, error } = await supabase
        .from('therapists')
        .select('*')
        .eq('status', 'pending_review');

      if (error) throw error;
      setPendingTherapists(data);
    } catch (error) {
      console.error('Error fetching pending therapists:', error);
      setError('Failed to fetch pending therapists');
    }
  };

  const handleApproval = async (therapistId, isApproved) => {
    try {
      const newStatus = isApproved ? 'approved' : 'rejected';
      const { error } = await supabase
        .from('therapists')
        .update({ status: newStatus })
        .eq('id', therapistId);

      if (error) throw error;

      // Update the local state to reflect the change
      setPendingTherapists(prevTherapists =>
        prevTherapists.filter(therapist => therapist.id !== therapistId)
      );

      // Optionally, you can show a success message here
      console.log(`Therapist ${therapistId} has been ${newStatus}`);

    } catch (error) {
      console.error('Error updating therapist status:', error);
      setError(`Failed to ${isApproved ? 'approve' : 'reject'} therapist`);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchDashboardData();
    } else {
      navigate('/admin');
    }
  }, [fetchDashboardData, isAuthenticated, navigate]);

  const handleCardClick = (dataType) => {
    setDisplayData(dataType);
    setPage(1);
    fetchTableData(dataType, 1);
  };

  const handleLoadMore = () => {
    fetchTableData(displayData, page + 1);
  };

  if (!dashboardData) {
    return <div className="flex justify-center items-center h-screen text-2xl text-gray-600">Loading...</div>;
  }

  return (
    <div className="bg-background min-h-screen p-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-primary mb-8">Admin Dashboard</h2>
        {error && <p className="bg-destructive text-destructive-foreground p-4 rounded-md mb-6">{error}</p>}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <StatCard
            title="Total Users"
            value={dashboardData.statistics.total_users}
            onClick={() => handleCardClick('users')}
          />
          <StatCard
            title="Total Therapists"
            value={dashboardData.statistics.total_therapists}
            onClick={() => handleCardClick('therapists')}
          />
          <StatCard title="New Users (30 days)" value={dashboardData.statistics.new_users_last_30_days} />
        </div>
        <div className="bg-card rounded-lg shadow-md p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4">
            {displayData === 'users' ? 'Recent Users' : 'Therapists'}
          </h3>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-muted">
                  <th className="p-3 text-left">Username</th>
                  <th className="p-3 text-left">Email</th>
                  <th className="p-3 text-left">Signup Date</th>
                </tr>
              </thead>
              <tbody>
                {dashboardData.tableData && dashboardData.tableData.map((item, index) => (
                  <tr key={index} className="border-b border-border">
                    <td className="p-3">{item.username || item.full_name}</td>
                    <td className="p-3">{item.email}</td>
                    <td className="p-3">{new Date(item.created_at).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {hasMore && (
            <div className="mt-4 text-center">
              <button
                onClick={handleLoadMore}
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-300"
              >
                Load More
              </button>
            </div>
          )}
        </div>
        <div className="bg-card rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4">Pending Therapist Approvals</h3>
          {pendingTherapists.length === 0 ? (
            <p>No pending therapist approvals.</p>
          ) : (
            <ul className="space-y-4">
              {pendingTherapists.map((therapist) => (
                <li key={therapist.id} className="bg-white shadow rounded-lg p-4">
                  <h4 className="text-lg font-semibold">{therapist.full_name}</h4>
                  <p>Email: {therapist.email}</p>
                  <p>License: {therapist.license_type} - {therapist.license_number}</p>
                  <div className="mt-2 space-x-2">
                    <button
                      onClick={() => handleApproval(therapist.id, true)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleApproval(therapist.id, false)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                    >
                      Reject
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value, onClick }) => (
  <div
    className={`bg-card rounded-lg shadow-md p-6 ${onClick ? 'cursor-pointer hover:shadow-lg transition-shadow duration-300' : ''}`}
    onClick={onClick}
  >
    <h3 className="text-lg font-semibold text-card-foreground mb-2">{title}</h3>
    <p className="text-3xl font-bold text-primary">{value}</p>
  </div>
);

export default AdminDashboard;