import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FiSend, FiMessageSquare, FiX } from 'react-icons/fi';
import { api } from '../../services/api';

const AIChatbot = ({ userEmail, getAccessToken, API_URL }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const chatMessagesRef = useRef(null);
  const initialMessageSent = useRef(false);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (chatMessages.length === 0 && !initialMessageSent.current) {
      setChatMessages([
        {
          role: 'assistant',
          content: "Welcome! I'm here to help you explore your health insights. What would you like to know?"
        }
      ]);
      initialMessageSent.current = true;
    }
  }, [chatMessages.length]);

  const handleSendMessage = async () => {
    if (!chatInput.trim()) return;

    const newUserMessage = { role: 'user', content: chatInput };
    setChatMessages(prevMessages => [...prevMessages, newUserMessage]);
    setChatInput('');

    try {
      const data = await api.chat.sendMessage(userEmail, chatInput, chatMessages);
      const newAssistantMessage = { role: 'assistant', content: data.response };
      setChatMessages(prevMessages => [...prevMessages, newAssistantMessage]);
      localStorage.setItem(`chatMessages_${userEmail}`, JSON.stringify([...chatMessages, newUserMessage, newAssistantMessage]));
    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prevMessages => [...prevMessages, { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' }]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className={`fixed bottom-4 right-4 transition-all duration-300 ease-in-out ${isCollapsed ? 'w-16 h-16' : 'w-96 h-[32rem]'}`}>
      {isCollapsed ? (
        <button
          onClick={() => setIsCollapsed(false)}
          className="w-full h-full bg-primary text-white rounded-full shadow-lg flex items-center justify-center hover:bg-primary-dark transition-colors duration-300"
        >
          <FiMessageSquare size={24} />
        </button>
      ) : (
        <div className="flex flex-col h-full bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-primary text-white p-4 flex justify-between items-center">
            <h2 className="text-xl font-bold">Oasys Assistant</h2>
            <button
              onClick={() => setIsCollapsed(true)}
              className="text-white hover:text-gray-200 transition-colors duration-300"
            >
              <FiX size={24} />
            </button>
          </div>
          <div ref={chatMessagesRef} className="flex-grow overflow-y-auto p-4 space-y-4">
            {chatMessages.map((message, index) => (
              <div key={index} className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                <div className={`max-w-3/4 p-3 rounded-lg ${message.role === 'assistant' ? 'bg-gray-100 text-gray-800' : 'bg-primary text-white'}`}>
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
          <div className="p-4 border-t border-gray-200">
            <div className="flex items-center">
              <input
                type="text"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Ask a question..."
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-full focus:outline-none focus:ring-2 focus:ring-primary"
              />
              <button
                onClick={handleSendMessage}
                className="bg-primary text-white px-4 py-2 rounded-r-full hover:bg-primary-dark transition-colors duration-300 flex items-center"
              >
                <FiSend className="mr-2" /> Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIChatbot;