import React from 'react';
import { Activity, Heart, Thermometer } from 'lucide-react';
import { DashboardCard, getStatusColor, round } from '../HealthDashboardUtils';

const OverviewTab = ({ healthData }) => {
    // Get unique data types, excluding 'auth'
    const availableDataTypes = healthData
        ? [...new Set(healthData.health_data.filter(data => data.type !== 'auth').map(data => data.type))]
        : [];

    return (
        <>
            <DashboardCard title="Health Overview" icon={<Activity className="text-blue-500" />}>
                {healthData ? (
                    <>
                        <p>{availableDataTypes.length} types of health data available:</p>
                        <ul className="list-disc list-inside mt-2">
                            {availableDataTypes.map(type => (
                                <li key={type} className="capitalize">{type}</li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <p>No health data available.</p>
                )}
            </DashboardCard>
        </>
    );
};

export default OverviewTab;
