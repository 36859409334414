import React from 'react';
import { Link } from 'react-router-dom';

const steps = [
  { title: 'Sign Up', path: '/therapist-signup', shortTitle: 'Sign Up' },
  { title: 'Professional Info', path: '/therapist-onboarding/professional-info', shortTitle: 'Prof. Info' },
  { title: 'Practice Details', path: '/therapist-onboarding/practice-details', shortTitle: 'Practice' },
  { title: 'Bio & Approach', path: '/therapist-onboarding/bio-and-approach', shortTitle: 'Bio' },
  { title: 'Verification', path: '/therapist-onboarding/verification-submission', shortTitle: 'Verify' }
];

const OnboardingProgress = ({ currentStep }) => {
  const currentIndex = steps.findIndex(step => step.path === currentStep);

  return (
    <div className="mb-8">
      <div className="flex justify-between mb-2">
        {steps.map((step, index) => {
          const isCompleted = index < currentIndex;
          const isCurrent = index === currentIndex;
          const isSignUp = index === 0;

          let className = 'text-xs font-medium ';
          if (isSignUp && !isCurrent) {
            className += 'text-gray-400 cursor-default';
          } else if (isCompleted || isCurrent) {
            className += 'text-primary cursor-pointer';
          } else {
            className += 'text-gray-400 cursor-not-allowed';
          }

          return isSignUp && !isCurrent ? (
            <span key={index} className={className}>
              {step.shortTitle}
            </span>
          ) : (
            <Link
              key={index}
              to={isCompleted || isCurrent ? step.path : '#'}
              className={className}
              onClick={(e) => {
                if (!isCompleted && !isCurrent) {
                  e.preventDefault();
                }
              }}
            >
              {step.shortTitle}
            </Link>
          );
        })}
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
        <div
          style={{ width: `${((currentIndex) / (steps.length - 1)) * 100}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary transition-all duration-300"
        ></div>
      </div>
    </div>
  );
};

export default OnboardingProgress;