import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../services/supabaseClient';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [therapistProfile, setTherapistProfile] = useState(null);

  const fetchProfile = useCallback(async (userId) => {
    try {
      // Check if the user is a therapist
      const { data: therapistData, error: therapistError } = await supabase
        .from('therapists')
        .select('*')
        .eq('id', userId)
        .single();
      
      if (!therapistError && therapistData) {
        setTherapistProfile(therapistData);
        setUserProfile(null);
      } else {
        // If not a therapist, fetch user data
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('id', userId)
          .single();
        
        if (userError) throw userError;
        setUserProfile(userData);
        setTherapistProfile(null);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
      if (session) {
        fetchProfile(session.user.id);
      }
    };
    checkAuth();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsAuthenticated(!!session);
      if (session) {
        fetchProfile(session.user.id);
      } else {
        setUserProfile(null);
        setTherapistProfile(null);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [fetchProfile]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    setUserProfile(null);
    setTherapistProfile(null);
  };

  return {
    isAuthenticated,
    setIsAuthenticated,
    userProfile,
    therapistProfile,
    setTherapistProfile,  
    handleLogout
  };
};

export default useAuth;