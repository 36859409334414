import React, { useState } from 'react';
import { Activity, Utensils, Moon, Dumbbell } from 'lucide-react';
import OverviewTab from './HealthDashboardTabs/OverviewTab';
import NutritionTab from './HealthDashboardTabs/NutritionTab';
import SleepTab from './HealthDashboardTabs/SleepTab';
import ActivityTab from './HealthDashboardTabs/ActivityTab';

const HealthDashboard = ({ healthData, nutritionData, sleepData, activityData }) => {
    const [activeTab, setActiveTab] = useState('overview');

    const TabButton = ({ tabName, label, icon }) => (
        <button
            className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === tabName ? 'bg-white text-blue-600' : 'bg-gray-200 text-gray-600'
                }`}
            onClick={() => setActiveTab(tabName)}
        >
            {icon}
            <span className="ml-2">{label}</span>
        </button>
    );

    return (
        <div className="bg-gray-100 p-6">
            <h2 className="text-2xl font-bold mb-6">Health Dashboard</h2>

            <div className="flex space-x-2 mb-4">
                <TabButton tabName="overview" label="Overview" icon={<Activity className="w-5 h-5" />} />
                <TabButton tabName="nutrition" label="Nutrition" icon={<Utensils className="w-5 h-5" />} />
                <TabButton tabName="sleep" label="Sleep" icon={<Moon className="w-5 h-5" />} />
                <TabButton tabName="activity" label="Activity" icon={<Dumbbell className="w-5 h-5" />} />
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
                {activeTab === 'overview' && <OverviewTab healthData={healthData} />}
                {activeTab === 'nutrition' && <NutritionTab nutritionData={nutritionData} />}
                {activeTab === 'sleep' && <SleepTab sleepData={sleepData} />}
                {activeTab === 'activity' && <ActivityTab activityData={activityData} />}
            </div>
        </div>
    );
};

export default HealthDashboard;