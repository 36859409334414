import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { supabase } from '../../services/supabaseClient';
import OnboardingProgress from '../../components/Onboarding/TherapistSignupBar';

const therapyTypes = [
  { value: 'Cognitive Behavioral Therapy (CBT)', label: 'Cognitive Behavioral Therapy (CBT)' },
  { value: 'Psychodynamic Therapy', label: 'Psychodynamic Therapy' },
  { value: 'Humanistic Therapy', label: 'Humanistic Therapy' },
  { value: 'Integrative Therapy', label: 'Integrative Therapy' },
  { value: 'Mindfulness-Based Therapy', label: 'Mindfulness-Based Therapy' },
  { value: 'Family Therapy', label: 'Family Therapy' },
  { value: 'Group Therapy', label: 'Group Therapy' },
  { value: 'Other', label: 'Other' },
];

const ageGroups = [
  { value: 'Children (0-12)', label: 'Children (0-12)' },
  { value: 'Adolescents (13-17)', label: 'Adolescents (13-17)' },
  { value: 'Young Adults (18-25)', label: 'Young Adults (18-25)' },
  { value: 'Adults (26-64)', label: 'Adults (26-64)' },
  { value: 'Seniors (65+)', label: 'Seniors (65+)' },
];

const insurancePlans = [
  { value: 'Blue Cross Blue Shield', label: 'Blue Cross Blue Shield' },
  { value: 'Aetna', label: 'Aetna' },
  { value: 'UnitedHealthcare', label: 'UnitedHealthcare' },
  { value: 'Cigna', label: 'Cigna' },
  { value: 'Medicare', label: 'Medicare' },
  { value: 'Medicaid', label: 'Medicaid' },
  { value: 'Out of Network', label: 'Out of Network' },
  { value: 'Self Pay', label: 'Self Pay' },
  { value: 'Other', label: 'Other' },
];

const PracticeDetails = ({ setIsOnboarding }) => {
  const [formData, setFormData] = useState({
    therapyTypes: [],
    ageGroups: [],
    telehealthAvailable: false,
    insurancePlans: [],
    address: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOnboarding(true);
    const fetchTherapistData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/therapist-login');
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          setFormData({
            therapyTypes: data.therapy_types || [],
            ageGroups: data.age_groups_served || [],
            telehealthAvailable: data.telehealth_available || false,
            insurancePlans: data.insurance_accepted || [],
            address: data.address || ''
          });
        }
      }
    };
    fetchTherapistData();
    return () => setIsOnboarding(false);
  }, [navigate, setIsOnboarding]);

  const handleChange = (selectedOptions, actionMeta) => {
    setFormData(prevState => ({
      ...prevState,
      [actionMeta.name]: selectedOptions ? selectedOptions.map(option => option.value) : []
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('therapists')
        .update({
          therapy_types: formData.therapyTypes,
          age_groups_served: formData.ageGroups,
          telehealth_available: formData.telehealthAvailable,
          insurance_accepted: formData.insurancePlans,
          address: formData.address
        })
        .eq('id', user.id);

      if (error) throw error;

      navigate('/therapist-onboarding/bio-and-approach');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-primary mb-6 text-center">Practice Details</h2>
        <OnboardingProgress currentStep="/therapist-onboarding/practice-details" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Types of Therapy Offered *</label>
            <Select
              isMulti
              name="therapyTypes"
              options={therapyTypes}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Age Groups Served *</label>
            <Select
              isMulti
              name="ageGroups"
              options={ageGroups}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="telehealthAvailable"
              name="telehealthAvailable"
              checked={formData.telehealthAvailable}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="telehealthAvailable" className="text-sm font-medium text-gray-700">Telehealth Available</label>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Accepted Insurance Plans</label>
            <Select
              isMulti
              name="insurancePlans"
              options={insurancePlans}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Office Address (optional)</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <button type="submit" className="w-full btn-primary">Next</button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default PracticeDetails;