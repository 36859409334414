import React, { useState } from 'react';
import CurrentSessionForm from './CurrentSessionForm';
import SessionContainer from './SessionContainer';
import PreviousSessionForm from './PreviousSessionForm';
import { api } from '../../../services/api';

const SessionPanel = ({ currentUser, patients, onSessionSaved }) => {
  const [activeView, setActiveView] = useState('default');
  const [sessionData, setSessionData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleStartCurrentSession = async (data) => {
    try {
      const sessionData = {
        ...data,
        patient_id: data.patient_id,  // Ensure this is the correct ID
        therapist_id: currentUser.id,
        date: new Date().toISOString().split('T')[0],
        time: new Date().toTimeString().split(' ')[0],
        format: 'SOAP', // Default format
        status: 'in_progress'
      };
      const createdSession = await api.sessions.createSession(sessionData);
      setSessionData(createdSession);
      setActiveView('currentSession');
    } catch (error) {
      console.error('Error creating session:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleEndSession = async (updatedSessionData) => {
    try {
      await api.sessions.updateSession(sessionData.id, {
        ...updatedSessionData,
        status: 'completed'
      });
      setSuccessMessage('Session saved successfully');
      setActiveView('default');
      onSessionSaved(); // Navigate to the sessions tab
    } catch (error) {
      console.error('Error ending session:', error);
      // Handle error
    }
  };

  const handleDeleteSession = async () => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        const result = await api.sessions.deleteSession(sessionData.id);
        setSuccessMessage(result.message || 'Session deleted successfully');
        setActiveView('default');
        setSessionData(null);
        if (typeof onSessionSaved === 'function') {
          onSessionSaved(); // Only call if it's a function
        }
      } catch (error) {
        console.error('Error deleting session:', error);
        setSuccessMessage(`Error deleting session: ${error.message}`);
      }
    }
  };

  const handleLogPreviousSession = async (data) => {
    try {
      const createdSession = await api.sessions.createSession({
        ...data,
        therapist_id: currentUser.id,
        status: 'completed'
      });
      setSessionData(createdSession);  // Use the createdSession
      setSuccessMessage('Session saved successfully');
      setActiveView('default');
      onSessionSaved(); // Navigate to the sessions tab
    } catch (error) {
      console.error('Error creating session:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-primary mb-4">Sessions</h2>
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      {activeView === 'default' && (
        <div className="space-x-4">
          <button
            onClick={() => {
              setActiveView('startCurrent');
              setSuccessMessage('');
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Start Current Session
          </button>
          <button
            onClick={() => {
              setActiveView('logPrevious');
              setSuccessMessage('');
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            Log Previous Session
          </button>
        </div>
      )}
      {activeView === 'startCurrent' && (
        <CurrentSessionForm
          patients={patients}
          onSubmit={handleStartCurrentSession}
          onCancel={() => setActiveView('default')}
        />
      )}
      {activeView === 'logPrevious' && (
        <PreviousSessionForm
          patients={patients}
          onSubmit={handleLogPreviousSession}
          onCancel={() => setActiveView('default')}
          currentUser={currentUser}
        />
      )}
      {activeView === 'currentSession' && sessionData && (
        <SessionContainer
          sessionData={sessionData}
          isCurrentSession={true}
          onEndSession={handleEndSession}
          onDeleteSession={handleDeleteSession}
          currentUser={currentUser}
          patients={patients}
        />
      )}
    </div>
  );
};

export default SessionPanel;