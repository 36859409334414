import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';

const UserLogin = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAndLogout = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        await supabase.auth.signOut();
        setIsAuthenticated(false);
      }
    };

    checkAndLogout();
  }, [setIsAuthenticated]);

  const getUserFriendlyErrorMessage = (error) => {
    const errorMap = {
      'Invalid login credentials': 'The email or password you entered is incorrect. Please try again.',
      'User not found': 'We couldn\'t find an account with that email. Please check your email or sign up.',
      'Email not confirmed': 'Please confirm your email address before logging in.',
    };

    return errorMap[error] || 'An unexpected error occurred. Please try again later or contact support.';
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) throw error;

      // Check if the user is a therapist
      const { data: therapistData, error: therapistError } = await supabase
        .from('therapists')
        .select('status')
        .eq('id', data.user.id)
        .single();

      if (!therapistError && therapistData) {
        setIsAuthenticated(true);
        if (therapistData.status === 'approved') {
          navigate('/therapist');
        } else if (therapistData.status === 'pending_review') {
          navigate('/therapist-onboarding/pending-approval');
        } else {
          navigate('/therapist-onboarding/professional-info');
        }
        return;
      }

      // If not a therapist, check user role in the users table
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('id', data.user.id)
        .single();

      if (userError) throw userError;

      setIsAuthenticated(true);

      if (userData.role === 'admin') {
        navigate('/admin-dashboard');
      } else {
        // User is a regular user
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError(getUserFriendlyErrorMessage(error.message));
      setIsAuthenticated(false);
    }
  };

  return (
    <div className="bg-background min-h-[80vh] flex items-center justify-center py-12">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-primary mb-6 text-center">Log In</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <input
            type="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <input
            type="password"
            placeholder="Password *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <button type="submit" className="w-full btn-primary">
            Log In
          </button>
        </form>
        {error && (
          <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            <p>{error}</p>
          </div>
        )}
        <p className="mt-4 text-center text-bodyText">
          Don't have an account?{' '}
          <Link to="/get-started" className="text-primary hover:underline">
            Sign Up
          </Link>
        </p>
        <p className="mt-2 text-center text-bodyText">
          Forgot your password?{' '}
          <Link to="/forgot-password" className="text-primary hover:underline">
            Reset it here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default UserLogin;
