import React from 'react';

const CodeOfEthicsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Code of Ethics</h3>
          <div className="mt-2 px-7 py-3 text-left">
            <p className="text-sm text-gray-500">
              1. Respect for Persons: Therapists must respect the dignity and worth of all people.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              2. Beneficence: Therapists should act in the best interest of their clients.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              3. Nonmaleficence: Therapists must avoid causing harm to their clients.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              4. Justice: Therapists should be fair and just in their treatment of all clients.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              5. Fidelity: Therapists should be faithful to their professional promises and responsibilities.
            </p>
            {/* Add more ethical principles as needed */}
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-primary text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeOfEthicsModal;