import React from 'react';

const SessionEditForm = ({ editingNote, handleEditChange, handleEditSubmit, setEditingNote, handleRegenerateSummary, isRegeneratingSummary }) => {
  return (
    <form onSubmit={handleEditSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Session Number</label>
          <input
            type="number"
            name="session_number"
            value={editingNote.session_number}
            onChange={handleEditChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Format</label>
          <select
            name="format"
            value={editingNote.format}
            onChange={handleEditChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="SOAP">SOAP</option>
            <option value="DAP">DAP</option>
            <option value="BIRP">BIRP</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
          <input
            type="date"
            name="date"
            value={editingNote.date}
            onChange={handleEditChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Time</label>
          <input
            type="time"
            name="time"
            value={editingNote.time}
            onChange={handleEditChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Original Notes</label>
        <textarea
          name="content"
          value={editingNote.content}
          onChange={handleEditChange}
          rows="4"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        ></textarea>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Summary</label>
        <div 
          className="bg-gray-100 p-3 rounded-md text-gray-700 prose prose-sm max-w-none"
          dangerouslySetInnerHTML={{ __html: editingNote.summary }}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="space-x-2">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => setEditingNote(null)}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 transition-colors duration-200"
          >
            Cancel
          </button>
        </div>
        <button
          type="button"
          onClick={handleRegenerateSummary}
          className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-200"
          disabled={isRegeneratingSummary}
        >
          {isRegeneratingSummary ? 'Regenerating...' : 'Regenerate Summary'}
        </button>
      </div>
    </form>
  );
};

export default SessionEditForm;