import React, { useEffect, useRef, useState } from 'react';
import { api } from '../../../services/api';
import { ZoomMtg } from '@zoom/meetingsdk';

const ZoomMeeting = ({ meetingNumber, userName }) => {
  const rootRef = useRef(null);
  const [meetingLink, setMeetingLink] = useState('');

  useEffect(() => {
    const initializeZoom = async () => {
      // Ensure the root element exists
      if (!rootRef.current) return;

      // Create necessary elements
      const zmmtgRoot = document.createElement('div');
      zmmtgRoot.id = 'zmmtg-root';
      rootRef.current.appendChild(zmmtgRoot);

      const meetingSDKElement = document.createElement('div');
      meetingSDKElement.id = 'meetingSDKElement';
      rootRef.current.appendChild(meetingSDKElement);

      // Initialize Zoom
      const ZoomMtg = window.ZoomMtg;
      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.13.0/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();

      try {
        const signature = await api.zoom.generateSignature(meetingNumber);
        const joinUrl = `https://zoom.us/wc/${meetingNumber}/join?prefer=1&un=${encodeURIComponent(userName)}`;
        setMeetingLink(joinUrl);

        ZoomMtg.init({
          leaveUrl: window.location.origin,
          success: (success) => {
            console.log(success);

            ZoomMtg.join({
              signature: signature,
              meetingNumber: meetingNumber,
              userName: userName,
              sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
              userEmail: '', // Add user email if available
              passWord: '', // Add password if required
              success: (success) => {
                console.log(success);
              },
              error: (error) => {
                console.error(error);
              }
            });
          },
          error: (error) => {
            console.error(error);
          }
        });
      } catch (error) {
        console.error('Error initializing or joining Zoom meeting:', error);
      }
    };

    initializeZoom();

    return () => {
      ZoomMtg.leaveMeeting({});
    };
  }, [meetingNumber, userName]);

  const copyMeetingLink = () => {
    navigator.clipboard.writeText(meetingLink).then(() => {
      alert('Meeting link copied to clipboard!');
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="bg-gray-100 p-4 rounded-md">
        <p className="font-semibold">Meeting Link:</p>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={meetingLink}
            readOnly
            className="flex-grow border rounded px-2 py-1"
          />
          <button
            onClick={copyMeetingLink}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            Copy
          </button>
        </div>
      </div>
      <div ref={rootRef} id="zmmtg-root" className="w-full h-[600px]"></div>
    </div>
  );
};

export default ZoomMeeting;