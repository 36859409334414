import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Utensils, ChevronDown, ChevronUp } from 'lucide-react';
import { DashboardCard, getStatusColor, round } from '../HealthDashboardUtils';

const NutritionTab = ({ nutritionData }) => {
    const [expandedDates, setExpandedDates] = useState({});

    // Sort nutrition data by date in descending order
    const sortedNutritionData = nutritionData && nutritionData.length > 0
        ? nutritionData.sort((a, b) =>
            new Date(b.metadata.start_time) - new Date(a.metadata.start_time)
        )
        : [];

    const toggleDateExpansion = (date) => {
        setExpandedDates(prev => ({ ...prev, [date]: !prev[date] }));
    };

    return (
        <DashboardCard title="Nutrition Summary" icon={<Utensils className="text-green-500" />}>
            {sortedNutritionData.length > 0 ? (
                <>
                    {/* Latest Nutrition Information */}
                    {sortedNutritionData[0] && (
                        <div className="mb-6 bg-blue-50 p-4 rounded-lg">
                            <h4 className="text-lg font-medium mb-2">
                                Latest Nutrition Data: {format(parseISO(sortedNutritionData[0].metadata.start_time), 'MMMM do, yyyy')}
                            </h4>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <h5 className="text-md font-medium mb-1">Daily Macronutrients</h5>
                                    <ul>
                                        <li className={getStatusColor(sortedNutritionData[0].summary.macros.calories, { good: 2000, average: 1500 })}>
                                            Calories: {round(sortedNutritionData[0].summary.macros.calories)}
                                        </li>
                                        <li>Protein: {round(sortedNutritionData[0].summary.macros.protein_g)}g</li>
                                        <li>Carbs: {round(sortedNutritionData[0].summary.macros.carbohydrates_g)}g</li>
                                        <li>Fat: {round(sortedNutritionData[0].summary.macros.fat_g)}g</li>
                                    </ul>
                                </div>
                                {/* Add micronutrients summary if needed */}
                            </div>
                        </div>
                    )}

                    {/* Historical Nutrition Data */}
                    <h4 className="text-lg font-medium mb-2">Historical Nutrition Data</h4>
                    {sortedNutritionData.map((nutritionEntry) => (
                        <div key={nutritionEntry.metadata.start_time} className="mb-4 border-b pb-4">
                            <div className="flex justify-between items-center cursor-pointer"
                                onClick={() => toggleDateExpansion(nutritionEntry.metadata.start_time)}>
                                <h4 className="text-lg font-medium">
                                    {format(parseISO(nutritionEntry.metadata.start_time), 'MMMM do, yyyy')}
                                </h4>
                                {expandedDates[nutritionEntry.metadata.start_time] ?
                                    <ChevronUp className="w-5 h-5" /> :
                                    <ChevronDown className="w-5 h-5" />
                                }
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                                <div>
                                    <h5 className="text-md font-medium mb-1">Daily Macronutrients</h5>
                                    <ul>
                                        <li className={getStatusColor(nutritionEntry.summary.macros.calories, { good: 2000, average: 1500 })}>
                                            Calories: {round(nutritionEntry.summary.macros.calories)}
                                        </li>
                                        <li>Protein: {round(nutritionEntry.summary.macros.protein_g)}g</li>
                                        <li>Carbs: {round(nutritionEntry.summary.macros.carbohydrates_g)}g</li>
                                        <li>Fat: {round(nutritionEntry.summary.macros.fat_g)}g</li>
                                    </ul>
                                </div>
                                {/* Add micronutrients summary if needed */}
                            </div>
                            {expandedDates[nutritionEntry.metadata.start_time] && (
                                <div className="mt-4">
                                    <h5 className="text-md font-medium mb-1">Meals</h5>
                                    <ul className="space-y-4">
                                        {nutritionEntry.meals.map((meal) => (
                                            <li key={meal.id} className="border p-4 rounded">
                                                <div className="flex justify-between">
                                                    <span className="font-semibold">{meal.name || `Meal ${meal.type}`}</span>
                                                    <span>{format(parseISO(meal.timestamp), 'h:mm a')}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <p>Calories: {round(meal.macros.calories)}</p>
                                                    <p>Protein: {round(meal.macros.protein_g)}g</p>
                                                    <p>Carbs: {round(meal.macros.carbohydrates_g)}g</p>
                                                    <p>Fat: {round(meal.macros.fat_g)}g</p>
                                                    {/* Add more details if needed */}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                </>
            ) : (
                <p>No nutrition data available.</p>
            )}
        </DashboardCard>
    );
};

export default NutritionTab;