// UserDashboardContent.js
import React, { useState, useEffect, useCallback } from 'react';
import AIChatbot from '../components/UserDashboard/AIChatbot';
import Profile from '../components/UserDashboard/UserProfile';
import TherapistList from '../components/UserDashboard/TherapistList';
import HealthDashboard from '../components/UserDashboard/HealthDashboard';
import { supabase } from '../services/supabaseClient';
import { api } from '../services/api';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const Dashboard = ({ userEmail, onSessionExpired, activeSection, setActiveSection, authMessage, setAuthMessage }) => {
  const [userTherapist, setUserTherapist] = useState(null);
  const [fullName, setFullName] = useState('');
  const [healthData, setHealthData] = useState(null);
  const [nutritionData, setNutritionData] = useState(null);
  const [sleepData, setSleepData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [connectedProviders, setConnectedProviders] = useState([]);

  const fetchUserData = useCallback(async () => {
    const { data, error } = await supabase
      .from('users')
      .select('full_name')
      .eq('email', userEmail)
      .single();

    if (error) {
      console.error('Error fetching user data:', error);
    } else if (data) {
      setFullName(data.full_name);
    } else {
      console.error('No user data found');
    }
  }, [userEmail]);

  const fetchHealthData = useCallback(async () => {
    try {
      const data = await api.terra.getHealthData(userEmail);
      setHealthData(data);
      console.log('Health Data in UserDashboardContent:', data);
    } catch (error) {
      console.error('Error fetching health data:', error);
    }
  }, [userEmail]);

  const fetchNutritionData = useCallback(async () => {
    try {
      const data = await api.terra.getNutritionData(userEmail);
      setNutritionData(data);
      console.log('Nutrition Data in UserDashboardContent:', data);
    } catch (error) {
      console.error('Error fetching nutrition data:', error);
    }
  }, [userEmail]);

  const fetchSleepData = useCallback(async () => {
    try {
      const data = await api.terra.getSleepData(userEmail);
      setSleepData(data);
      console.log('Sleep Data in UserDashboardContent:', data);
    } catch (error) {
      console.error('Error fetching sleep data:', error);
    }
  }, [userEmail]);

  const fetchActivityData = useCallback(async () => {
    try {
      const data = await api.terra.getActivityData(userEmail);
      setActivityData(data);
      console.log('Activity Data in UserDashboardContent:', data);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  }, [userEmail]);

  const fetchConnectedProviders = useCallback(async () => {
    try {
      const data = await api.terra.getConnectedProviders(userEmail);
      setConnectedProviders(data.connected_providers);
    } catch (error) {
      console.error('Error fetching connected providers:', error);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userEmail) {
      fetchUserData();
      fetchHealthData();
      fetchNutritionData();
      fetchSleepData();
      fetchActivityData();
      fetchConnectedProviders();
    }
  }, [userEmail, fetchUserData, fetchHealthData, fetchNutritionData, fetchSleepData, fetchActivityData, fetchConnectedProviders]);

  const handleTherapistSelection = useCallback((therapist) => {
    setUserTherapist(therapist);
    if (!therapist || therapist.approved === false) {
      setActiveSection('myTherapist');
    }
  }, [setActiveSection]);

  const getAccessToken = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error getting session:', error);
      return null;
    }
    return data.session?.access_token;
  };

  const handleConnectHealth = async () => {
    try {
      const { url } = await api.terra.generateWidgetSession(userEmail);
      console.log('Terra widget URL:', url);
      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error generating Terra widget session:', error);
    }
  };

  const handleDisconnectProvider = async (providerId) => {
    if (window.confirm(`Warning: Disconnecting this provider will delete all associated data. Are you sure you want to proceed?`)) {
      try {
        await api.terra.disconnectProvider(userEmail, providerId);
        console.log(`Provider ${providerId} disconnected successfully`);
        fetchHealthData();
        fetchConnectedProviders();
      } catch (error) {
        console.error('Error disconnecting provider:', error);
      }
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-2xl font-bold text-primary mb-6">My Dashboard</h2>
          <nav className="space-y-2">
            {['overview', 'connectHealth', 'myTherapist', 'profile'].map((section) => (
              <button
                key={section}
                className={`w-full text-left px-4 py-2 rounded-md transition-colors duration-150 ease-in-out ${activeSection === section
                  ? 'bg-primary text-white'
                  : 'text-gray-600 hover:bg-gray-100'
                  }`}
                onClick={() => setActiveSection(section)}
              >
                {section === 'myTherapist' ? 'My Therapist' :
                  section === 'connectHealth' ? 'Data Integration' :
                    section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="flex-1 overflow-auto p-8">
        <div className="max-w-4xl mx-auto">
          {/* Display success or failure message */}
          {authMessage && (
            <div className={`border px-4 py-3 rounded relative mb-4 ${authMessage.startsWith('Failed') ? 'bg-red-100 border-red-400 text-red-700' : 'bg-green-100 border-green-400 text-green-700'}`}>
              <span className="block sm:inline">{authMessage}</span>
              <span onClick={() => setAuthMessage(null)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg className={`fill-current h-6 w-6 ${authMessage.startsWith('Failed') ? 'text-red-500' : 'text-green-500'}`} role="button" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"><title>Close</title><path
                    d="M14.348 5.652a1 1 0 10-1.414-1.414L10 7.172 7.066 4.238a1 1 0 00-1.414 1.414L8.828 10l-3.176 3.176a1 1 0 101.414 1.414L10 12.828l3.176 3.176a1 1 0 001.414-1.414L11.172 10l3.176-3.176z" /></svg>
              </span>
            </div>
          )}
          {activeSection === 'overview' && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-2xl font-bold text-primary mb-4">Welcome, {fullName}!</h2>
              <HealthDashboard
                healthData={healthData}
                nutritionData={nutritionData}
                sleepData={sleepData}
                activityData={activityData}
              />
              <button
                onClick={() => { fetchHealthData(); fetchNutritionData(); fetchSleepData(); fetchActivityData(); }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4"
              >
                Refresh Data
              </button>
            </div>
          )}

          {activeSection === 'connectHealth' && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-2xl font-bold text-primary mb-4">Connect Health Data</h2>
              <button
                onClick={handleConnectHealth}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Connect Health Provider
              </button>

              {connectedProviders.length > 0 ? (
                <div className="mt-6">
                  <h3 className="text-xl font-semibold mb-2">Connected Providers:</h3>
                  <ul>
                    {connectedProviders.map((provider) => (
                      <li key={provider} className="flex items-center justify-between py-2">
                        <span>{provider}</span>
                        <button
                          onClick={() => handleDisconnectProvider(provider)}
                          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                        >
                          Disconnect
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="mt-6">No providers connected.</p>
              )}

              <button
                onClick={fetchConnectedProviders}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4"
              >
                Refresh Providers
              </button>
            </div>
          )}

          {activeSection === 'myTherapist' && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <TherapistList onTherapistSelect={handleTherapistSelection} userTherapist={userTherapist} />
            </div>
          )}

          {activeSection === 'profile' && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <Profile userEmail={userEmail} />
            </div>
          )}
        </div>
      </div>

      <AIChatbot userEmail={userEmail} getAccessToken={getAccessToken} API_URL={API_URL} />
    </div>
  );
};

export default Dashboard;
