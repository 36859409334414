import React, { useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';
import { FaEnvelope, FaIdCard, FaUserMd, FaPhone, FaLinkedin, FaMapMarkerAlt, FaPencilAlt, FaUpload, FaTrash } from 'react-icons/fa';
import defaultAvatar from '../../assets/default-avatar.png';

// Import constants
import { therapyTypes, ageGroups, insurancePlans } from '../Onboarding/Constants';

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, l => l.toUpperCase());
};

const TherapistProfile = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const { data, error } = await supabase
        .from('therapists')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      if (!data) throw new Error('No profile data found for this user');

      setProfile(data);
      setEditedProfile(data);
    } catch (error) {
      console.error('Error fetching therapist profile:', error);
      setError(error.message || 'Failed to fetch profile');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedProfile(profile);
  };

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from('therapists')
        .update(editedProfile)
        .eq('id', profile.id);

      if (error) throw error;

      setProfile(editedProfile);
      setIsEditing(false);
      // Optionally, show a success message
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleArrayChange = (name, value) => {
    setEditedProfile(prev => ({
      ...prev,
      [name]: Array.isArray(value)
        ? value.map(capitalizeWords)
        : value.split(',').map(item => capitalizeWords(item.trim()))
    }));
  };

  const handleImageUpload = async (event) => {
    try {
      setUploadingImage(true);
      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${profile.id}/${fileName}`;

      console.log('Attempting to upload file:', filePath);

      let { data, error: uploadError } = await supabase.storage
        .from('therapist_profile_pictures')
        .upload(filePath, file);

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw uploadError;
      }

      console.log('File uploaded successfully:', data);

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('therapist_profile_pictures')
        .getPublicUrl(filePath);

      if (urlError) {
        console.error('Error getting public URL:', urlError);
        throw urlError;
      }

      console.log('Public URL:', publicUrl);

      setEditedProfile(prev => ({ ...prev, profile_picture_url: publicUrl }));
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image: ' + error.message);
    } finally {
      setUploadingImage(false);
    }
  };

  const handleRemoveImage = () => {
    setEditedProfile(prev => ({ ...prev, profile_picture_url: null }));
  };

  if (loading) return <div className="text-center py-4">Loading profile...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  if (!profile) return <div className="text-center py-4">No profile data available</div>;

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-primary">Therapist Profile</h2>
        {!isEditing && (
          <button onClick={handleEdit} className="btn-secondary flex items-center">
            <FaPencilAlt className="mr-2" /> Edit Profile
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-1">
          <div className="relative w-32 h-32 mx-auto mb-4">
            <img
              src={editedProfile.profile_picture_url || defaultAvatar}
              alt="Profile"
              className="rounded-full w-full h-full object-cover"
              onError={(e) => {
                console.error('Error loading profile picture:', e.target.src);
                e.target.onerror = null;
                e.target.src = defaultAvatar;
              }}
            />
            {isEditing && (
              <div className="absolute bottom-0 right-0 flex">
                <label htmlFor="profile-picture-upload" className="cursor-pointer bg-primary text-white rounded-full p-2 mr-2">
                  <FaUpload />
                </label>
                <input
                  id="profile-picture-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <button onClick={handleRemoveImage} className="bg-red-500 text-white rounded-full p-2">
                  <FaTrash />
                </button>
              </div>
            )}
          </div>
          {uploadingImage && <p className="text-center text-sm text-gray-500">Uploading...</p>}
          {profile.linkedin_url && (
            <a href={profile.linkedin_url} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center text-blue-600 hover:text-blue-800">
              <FaLinkedin size={24} className="mr-2" /> LinkedIn Profile
            </a>
          )}
        </div>
        <div className="md:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ProfileItem icon={<FaUserMd />} label="Full Name" value={isEditing ? editedProfile.full_name : profile.full_name} isEditing={isEditing} name="full_name" onChange={handleChange} />
            <ProfileItem icon={<FaEnvelope />} label="Email" value={isEditing ? editedProfile.email : profile.email} isEditing={isEditing} name="email" onChange={handleChange} />
            <ProfileItem icon={<FaIdCard />} label="License" value={isEditing ? `${editedProfile.license_type} - ${editedProfile.license_number}` : `${profile.license_type} - ${profile.license_number}`} isEditing={isEditing} name="license_number" onChange={handleChange} />
            <ProfileItem
              icon={<FaUserMd />}
              label="Experience"
              value={isEditing ? editedProfile.years_of_experience : `${profile.years_of_experience} years`}
              isEditing={isEditing}
              name="years_of_experience"
              onChange={handleChange}
              type="number"
              min="0"
            />
            <ProfileItem icon={<FaPhone />} label="Phone" value={isEditing ? editedProfile.phone_number : profile.phone_number} isEditing={isEditing} name="phone_number" onChange={handleChange} />
            <ProfileItem icon={<FaMapMarkerAlt />} label="Address" value={isEditing ? editedProfile.address : profile.address} isEditing={isEditing} name="address" onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <ProfileSection
          title="Therapy Types"
          items={isEditing ? editedProfile.therapy_types : profile.therapy_types}
          isEditing={isEditing}
          name="therapy_types"
          onChange={handleArrayChange}
          options={therapyTypes}
        />
        <ProfileSection
          title="Specializations"
          items={isEditing ? editedProfile.specializations : profile.specializations}
          isEditing={isEditing}
          name="specializations"
          onChange={handleArrayChange}
        />
        <ProfileSection
          title="Languages"
          items={isEditing ? editedProfile.languages : profile.languages}
          isEditing={isEditing}
          name="languages"
          onChange={handleArrayChange}
        />
        <ProfileSection
          title="Age Groups Served"
          items={isEditing ? editedProfile.age_groups_served : profile.age_groups_served}
          isEditing={isEditing}
          name="age_groups_served"
          onChange={handleArrayChange}
          options={ageGroups}
        />
        <ProfileSection title="Education" items={isEditing ? editedProfile.education : profile.education} isEditing={isEditing} name="education" onChange={handleArrayChange} isList={true} />
        <ProfileSection
          title="Accepted Insurance Plans"
          items={isEditing ? editedProfile.insurance_accepted : profile.insurance_accepted}
          isEditing={isEditing}
          name="insurance_accepted"
          onChange={handleArrayChange}
          options={insurancePlans}
        />
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold text-primary mb-2">Professional Bio</h3>
        {isEditing ? (
          <textarea
            name="professional_bio"
            value={editedProfile.professional_bio}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
          />
        ) : (
          <p className="text-gray-700">{profile.professional_bio}</p>
        )}
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold text-primary mb-2">Therapy Approach</h3>
        {isEditing ? (
          <textarea
            name="therapy_approach"
            value={editedProfile.therapy_approach}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
          />
        ) : (
          <p className="text-gray-700">{profile.therapy_approach}</p>
        )}
      </div>
      {isEditing && (
        <div className="mt-6 flex justify-end space-x-4">
          <button onClick={handleCancel} className="btn-secondary">Cancel</button>
          <button onClick={handleSave} className="btn-primary">Save Changes</button>
        </div>
      )}
    </div>
  );
};

const ProfileItem = ({ icon, label, value, isEditing, name, onChange, type = "text", min }) => (
  <div className="flex items-center">
    <div className="text-primary mr-2">{icon}</div>
    <div className="flex-grow">
      <span className="font-semibold">{label}:</span>
      {isEditing ? (
        <input
          type={type}
          name={name}
          value={type === "number" ? parseInt(value) : value}
          onChange={(e) => {
            const newValue = type === "number" ? parseInt(e.target.value) : e.target.value;
            onChange({ target: { name, value: newValue } });
          }}
          className="w-full p-1 border rounded ml-2"
          min={min}
        />
      ) : (
        <span className="ml-2">{value || 'N/A'}</span>
      )}
    </div>
  </div>
);

const ProfileSection = ({ title, items, isEditing, name, onChange, isList = false, options }) => (
  <div>
    <h3 className="text-xl font-semibold text-primary mb-2">{title}</h3>
    {isEditing ? (
      options ? (
        <select
          multiple
          name={name}
          value={items || []}
          onChange={(e) => onChange(name, Array.from(e.target.selectedOptions, option => option.value))}
          className="w-full p-2 border rounded"
        >
          {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      ) : (
        <input
          type="text"
          name={name}
          value={Array.isArray(items) ? items.map(capitalizeWords).join(', ') : ''}
          onChange={(e) => onChange(name, e.target.value.split(',').map(item => capitalizeWords(item.trim())))}
          className="w-full p-2 border rounded"
          placeholder={`Enter ${title.toLowerCase()} separated by commas`}
        />
      )
    ) : isList ? (
      <ul className="list-disc list-inside text-gray-700">
        {Array.isArray(items) && items.map((item, index) => (
          <li key={index}>{capitalizeWords(item)}</li>
        ))}
      </ul>
    ) : (
      <ChipList items={items ? items.map(capitalizeWords) : []} />
    )}
  </div>
);

const ChipList = ({ items }) => (
  <div className="flex flex-wrap gap-2">
    {items?.map((item, index) => (
      <span key={index} className="bg-primary text-white px-2 py-1 rounded-full text-sm">
        {item}
      </span>
    ))}
  </div>
);

export default TherapistProfile;