import React from 'react';
import { FiActivity, FiHeart, FiShield, FiCalendar } from 'react-icons/fi';

const HomePage = () => {
  return (
    <div className="bg-gradient-to-br from-background to-lightGray min-h-screen">
      <div className="container mx-auto px-4 py-16">
        <header className="text-center mb-16">
          <h1 className="text-6xl font-bold text-primary mb-4 animate-fade-in">
            Welcome to <span className="text-secondary">oasys</span>
          </h1>
          <p className="text-xl text-foreground max-w-2xl mx-auto">
            Your personal mental health companion, powered by AI and backed by science.
          </p>
        </header>
        
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <FeatureCard 
            icon={<FiActivity className="w-8 h-8" />}
            title="AI-Powered Insights"
            description="Gain deep understanding of your mental well-being through advanced AI analysis."
          />
          <FeatureCard 
            icon={<FiHeart className="w-8 h-8" />}
            title="Holistic Health Tracking"
            description="Integrate data from wearables and DNA tests for a comprehensive health overview."
          />
          <FeatureCard 
            icon={<FiShield className="w-8 h-8" />}
            title="Privacy-First Approach"
            description="Your data is encrypted and protected with industry-leading security measures."
          />
        </div>
        
        <div className="flex flex-col items-center space-y-6">
          <p className="text-foreground text-center max-w-lg">
            Not ready to get started just yet? Book a demo to see how Oasys can transform your mental health journey.
          </p>
          <a 
            href="https://calendly.com/hashemabdou/oasys-platform-demo" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="btn-primary group flex items-center"
          >
            <FiCalendar className="mr-2" />
            Book a Demo
          </a>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1">
    <div className="text-secondary mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold text-primary mb-2">{title}</h3>
    <p className="text-foreground">{description}</p>
  </div>
);

export default HomePage;