import React, { useState } from 'react';

const CurrentSessionForm = ({ patients, onSubmit, onCancel }) => {
  const [patientId, setPatientId] = useState('');
  const [sessionNumber, setSessionNumber] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!patientId || !sessionNumber) {
      setError('Please select a patient and enter a session number.');
      return;
    }
    onSubmit({ patient_id: patientId, session_number: parseInt(sessionNumber) });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="patient" className="block text-sm font-medium text-gray-700">Patient</label>
        <select
          id="patient"
          value={patientId}
          onChange={(e) => setPatientId(e.target.value)}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        >
          <option value="">Select a patient</option>
          {patients.map((patient) => (
            <option key={patient.id} value={patient.id}>{patient.full_name}</option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="sessionNumber" className="block text-sm font-medium text-gray-700">Session Number</label>
        <input
          type="number"
          id="sessionNumber"
          value={sessionNumber}
          onChange={(e) => setSessionNumber(e.target.value)}
          required
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      {error && <p className="text-red-500">{error}</p>}
      <div className="flex justify-end space-x-2">
        <button type="button" onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Start Session
        </button>
      </div>
    </form>
  );
};

export default CurrentSessionForm;