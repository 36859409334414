import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import OnboardingProgress from '../../components/Onboarding/TherapistSignupBar';

const BioAndApproach = ({ setIsOnboarding }) => {
  const [formData, setFormData] = useState({
    professionalBio: '',
    therapyApproach: '',
    profilePicture: null,
    profilePictureUrl: null
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOnboarding(true);
    const fetchTherapistData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/therapist-login');
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching therapist data:', error);
        } else if (data) {
          setFormData({
            professionalBio: data.professional_bio || '',
            therapyApproach: data.therapy_approach || '',
            profilePicture: null,
            profilePictureUrl: data.profile_picture_url || null
          });
        }
      }
    };
    fetchTherapistData();
    return () => setIsOnboarding(false);
  }, [navigate, setIsOnboarding]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      profilePicture: e.target.files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      let profilePictureUrl = formData.profilePictureUrl;

      if (formData.profilePicture) {
        const fileExt = formData.profilePicture.name.split('.').pop();
        const fileName = `${user.id}_profile.${fileExt}`;
        const { error: uploadError } = await supabase.storage
          .from('therapist_profile_pictures')
          .upload(fileName, formData.profilePicture, { upsert: true });

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('therapist_profile_pictures')
          .getPublicUrl(fileName);

        profilePictureUrl = publicUrl;
      }

      const { error } = await supabase
        .from('therapists')
        .update({
          professional_bio: formData.professionalBio,
          therapy_approach: formData.therapyApproach,
          profile_picture_url: profilePictureUrl
        })
        .eq('id', user.id);

      if (error) throw error;

      navigate('/therapist-onboarding/verification-submission');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-background min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-primary mb-6 text-center">Bio and Approach</h2>
        <OnboardingProgress currentStep="/therapist-onboarding/bio-and-approach" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="professionalBio" className="block text-sm font-medium text-gray-700 mb-1">Professional Bio (250 words max) *</label>
            <textarea
              id="professionalBio"
              name="professionalBio"
              value={formData.professionalBio}
              onChange={handleChange}
              required
              maxLength={250}
              rows={5}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="therapyApproach" className="block text-sm font-medium text-gray-700 mb-1">How do you approach therapy? (50 words max) *</label>
            <textarea
              id="therapyApproach"
              name="therapyApproach"
              value={formData.therapyApproach}
              onChange={handleChange}
              required
              maxLength={50}
              rows={3}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="profilePicture" className="block text-sm font-medium text-gray-700 mb-1">Profile Picture (Optional)</label>
            <input
              type="file"
              id="profilePicture"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <button type="submit" className="w-full btn-primary">Next</button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default BioAndApproach;